import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/combineLatest';
import { Auth } from 'aws-amplify';
import { Store } from '@ngrx/store';

import { HttpAuthClient } from './http_auth';
import { utilsAuth      } from './utils_auth';
import UploadedFile from '../models/uploaded_file';
import S3UploadResult from '../models/s3_upload_result';
import UploadError from '../models/upload_error';
import {  MESSAGE_TYPE_START, MESSAGE_TYPE_UPLOADED, MESSAGE_TYPE_ERROR, MESSAGE_TYPE_PROGRESS } from '../models/message_type';
import FileToUpload, { getHash } from '../models/file_to_upload';
import { TagService } from './tag.service';
import { AppState } from '../store/state/app.state';
import { HttpServiceBase } from './http_service_base';
import { UploadChunk } from '../models/upload_chunk';

@Injectable({
  providedIn: 'root'
})
export class UploadsService extends HttpServiceBase {
  constructor(
    private httpAuth: HttpAuthClient,
    private tagService: TagService,
    protected store : Store<AppState>
  ) {
    super(store);
  }

  getUploadUsers() : Observable<string[]> {
    // @ts-ignore
    return this.httpAuth.get(`${this.serverUrl}/api/uploads/users`);
  }

  getUploads() : Observable<UploadedFile[]> {
    console.log('serverUrl:', this.serverUrl);

    /* By default show only not deleted and  */
    let params = new HttpParams();
    params = params.append('uploaded' , '1');
    params = params.append('remaining', '1');

    // @ts-ignore
    return this.httpAuth.get<any>(`${this.serverUrl}/api/uploads/`, {params : params})
    .map(result => result.data)    
    .combineLatest(
      this.tagService.getTags('', 10000),
      (data, tags) => data.map(upload => (<UploadedFile>{
        ...upload,
        tags: tags.filter(t => upload.tags.findIndex(t2 => t2 == t.id) >= 0)
      }))
    );
  }

  getUploadsEx(filters: any[], sorts: any[], limit: number, offset: number) : Observable<UploadedFile[]> {
    // @ts-ignore
    let sort_request: string = "";
    for (let i = 0; i < sorts.length; i++)
    {
      let sort = sorts[i];
      sort_request += ((i == 0) ? '' : ',') + sort.name + ':' + sort.order.toString();
    }

    let params = new HttpParams();
    if (sort_request !== "") {
      params = params.append("sort", sort_request);
    }

    if (limit > 0) {
      params = params.append("limit", limit.toString());
    }    
    if (offset > 0) {
      params = params.append("offset", offset.toString());
    }
    for (let i = 0; i < filters.length; i++)
    {
      let filter = filters[i];
      params = params.append(filter.name, filter.value);
    }    
    return this.httpAuth.get<any>(`${this.serverUrl}/api/uploads/`, {params : params}).map(result => result.data );
  }



  addUpload(file: UploadedFile) : Observable<UploadedFile> {
    // @ts-ignore
    return this.httpAuth.put(`${this.serverUrl}/api/uploads/`, file, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  }

  async uploadFile(
    fileToUpload: FileToUpload,
    currentUser: any,
    progressCallback: (number) => void)
  : Promise<UploadedFile> {
    console.log(`uploads.service: [${fileToUpload.file.name}]: started uploading`);

    try {
      const uploadResult = await this.uploadToS3(
        fileToUpload,
        { 
          name  : currentUser.username,
          token : currentUser.getSignInUserSession().getAccessToken().getJwtToken(),
          dropTo: JSON.stringify(utilsAuth.getDroppedPrivelegeArray())
        },
        progressCallback);

      console.log(`uploads.service: [${fileToUpload.file.name}]: uploaded to s3`);;

      /* I would prefer this to be redownloaded from server */
      const result = this.addUpload({
        id: 0,
        url: uploadResult.Location,
        fileName: fileToUpload.file.name,
        originalName: fileToUpload.file.name,                
        hash : await getHash(fileToUpload),
        // @ts-ignore
        contentType: file.contentType,
        size: fileToUpload.file.size,
        uploader_id: currentUser.username,
        upload_date:"",
        thumbnail_url: "",
        thumbnail_video_url: "",
        comment: fileToUpload.comment,
        tags: fileToUpload.tags,
        deleted: false,
        uploaded: true
      }).toPromise();

      console.log(`uploads.service: [${fileToUpload.file.name}]: uploaded`);

      return result;
    }
    catch(err) {
      console.log(`uploads.service: [${fileToUpload.file.name}]: upload error:`, err);

      if (typeof err === 'string') {
        throw {
          fileName: fileToUpload.file.name,
          error: err
        };
      }
      else {
        throw 'Unknown error';
      }
    }
  }

  uploadFiles(files: [FileToUpload, (number) => void][]) : Promise<(UploadedFile | UploadError)[]> {
    let uploadResults = new Array(files.length).fill(null);
    let numberOfUploadingFiles = 0;

    if (files.length == 0) {
      return Promise.resolve([]);
    }

    for (let idx in files) {
      const file = files[idx][0];
      console.log('uploads.service: file to upload:', file.file.name, file.progress);
      const cb = files[idx][1];
      if (file.progress >= 100) {
        uploadResults[idx] = {
          fileName: file.file.name
        };
      }
      else {
        file.progress = 0.001;
        cb(file.progress);
      }
    }

    Auth.currentAuthenticatedUser()
    .then(currentUser => {
      let interval = setInterval(() => {
        const nextFileIdx = uploadResults.findIndex((r, idx) => r === null && files[idx][0].progress < 0.5);

        if (nextFileIdx < 0) {
          clearInterval(interval);
          console.log('uploads.service: files:', files.map(f => f[0].file.name));
          console.log('uploads.service: current results:', uploadResults);
          console.log('uploads.service: current progresses:', files.map(f => f[0].progress));
          console.log('uploads.service: next file idx:', nextFileIdx);
          return;
        }

        const [fileToUpload, progressCallback] = files[nextFileIdx];
        const progressCallback2 = (progress) => {
          fileToUpload.progress = progress;
          progressCallback(progress);
        }

        if (numberOfUploadingFiles < 2) {
          numberOfUploadingFiles = Math.min(2, numberOfUploadingFiles + 1);

          console.log('uploads.service: files:', files.map(f => f[0].file.name));
          console.log('uploads.service: current results:', uploadResults);
          console.log('uploads.service: current progresses:', files.map(f => f[0].progress));
          console.log('uploads.service: next file idx:', nextFileIdx);

          fileToUpload.progress = 0.5;

          this.uploadFile(fileToUpload, currentUser, progressCallback2)
          .then(res => {
            uploadResults[nextFileIdx] = res;
          })
          .catch(err => {
            uploadResults[nextFileIdx] = err;
          })
          .finally(() => {
            numberOfUploadingFiles = Math.max(0, numberOfUploadingFiles - 1);
          });
        }
      }, 500);
    });

    return new Promise((resolve, reject) => {
      let interval = setInterval(() => {
        if (uploadResults.indexOf(null) < 0) {
          clearInterval(interval);
          console.log('uploadResults:', uploadResults);
          resolve(uploadResults);
        }
      }, 500)
    })
  }

  uploadToS3(file: FileToUpload, currentUser: any, progressCallback: (number) => void) : Promise<S3UploadResult> {
    return new Promise((resolve, reject) => {
      if (typeof Worker !== 'undefined') {

        console.log('uploadToS3', file.file.name, currentUser);

        const worker = new Worker('../workers/file-uploader.worker', { type: 'module' });
        console.log(worker);
        worker.onmessage = ({ data }) => {
          switch (data.type) {
            case MESSAGE_TYPE_UPLOADED:
              console.log(`uploadToS3: [${file.file.name}]: finished`, data.result);
              resolve(data.result);
              worker.terminate();
              break;
            case MESSAGE_TYPE_ERROR:
              console.log(`uploadToS3: [${file.file.name}]: error`, data.error);
              reject(data.error);
              worker.terminate();
              break;
            case MESSAGE_TYPE_PROGRESS:
              console.log(`uploadToS3: [${file.file.name}]: progress`, data.progress);
              if (data.progress) {
                progressCallback(data.progress);
              }
              break;
          }
        };
        console.log(`uploadToS3: sending '${MESSAGE_TYPE_START}'`);
        try {
          worker.postMessage({
            type : MESSAGE_TYPE_START,
            file: file,
            user: JSON.parse(JSON.stringify(currentUser)),
            serverUrl: this.serverUrl
          });
        }
        catch (err) {
          console.log(`Error: uploadToS3: failed to send '${MESSAGE_TYPE_START}'`, err);
          reject(err);
        }
      } else {
        console.log('Error: uploadToS3: Web workers are not supported.');
        reject('Error: uploadToS3: Web workers are not supported.');
      }
    });
  }

  async deleteUploads(files: UploadedFile[]) : Promise<HttpErrorResponse> {
    console.log("deleteUpload", files);

    try {
      await this.httpAuth.post(
        `${this.serverUrl}/api/uploads/delete/`,
        {
          files: files.map(f => f.fileName)
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }).toPromise();
    }
    catch(e) {
      return e;
    }

    return null;
  }

  getChunks(video_id:number ) : Observable<UploadChunk[]> {
    let params = new HttpParams();    
    params = params.append("video_id", video_id.toString());    
    return this.httpAuth.get(`${this.serverUrl}/api/uploads/chunks`, {params: params});
  }
}
