import { ActionReducerMap } from '@ngrx/store';
import { AppState } from '../state/app.state';
import { configurationReducers } from './configuration.reducers';
import { basketComponentReducers } from './basket-component.reducers';
import { buildCollectionReducer } from './collection.reducers';
import { jobsReducers } from './jobs.reducers';
import { annotatorComponentReducers } from './annotator-component.reducers';
import { AppActions, EAppActions } from '../actions/app.actions';
import { notificationsReducers } from './notifications.reducers';
import { contentsTabComponentReducers } from './contents-tab-component.reducers';
import { ECollectionItemType, CollectionActions, ECollectionActions } from '../actions/collection.actions';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';
import { frameLabelsReducers } from './frame-labels.reducers';
import { Basket } from 'src/app/models/basket';
import UploadedFile from 'src/app/models/uploaded_file';
import { VideoFrame } from 'src/app/models/video_frame';
import { backendConfigurationReducers } from './backend-configuration.reducers';

export function selectedTabNumberReducer( 
	state = 1,
	action: AppActions
) : number {
	switch (action.type) {
		case EAppActions.NavigateToTab:
			return action.tabNumber
		default:
			return state;
	}
}

const _ontologyReducer = buildCollectionReducer<ObjectOntologyClass>(ECollectionItemType.Ontology);
export function ontologyReducer(state, action) {
	return _ontologyReducer(state, action);
}

const _basketsReducer = buildCollectionReducer<Basket>(ECollectionItemType.Basket);
export function basketsReducer(state, action) {
	return _basketsReducer(state, action);
}

const _videosReducer = buildCollectionReducer<UploadedFile>(ECollectionItemType.Video);
export function videosReducer(state, action) {
	return _videosReducer(state, action);
}

const _framesReducer = buildCollectionReducer<VideoFrame>(ECollectionItemType.VideoFrame);
export function framesReducer(state, action) {
	return _framesReducer(state, action);
}

export const appReducers : ActionReducerMap<AppState, any> = {
	configuration: configurationReducers,
	backendConfiguration: backendConfigurationReducers,
	selectedTabNumber: selectedTabNumberReducer,
	ontology: ontologyReducer,
	baskets: basketsReducer,
	videos: videosReducer,
	frames: framesReducer,
	jobs: jobsReducers,
	frameLabels: frameLabelsReducers,
	notifications: notificationsReducers,
	basketComponent: basketComponentReducers,
	annotatorComponent: annotatorComponentReducers,
	contentsTabComponent: contentsTabComponentReducers
}
