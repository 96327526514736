import { VideoFrameFilters } from "src/app/models/video_frame_filters";

export interface ContentsTabComponentState {
  selectedFrames: number[], // Selected frame client ids
  selectedVideos: number[], // Selected video client ids
  selectedTabNumber: number, // Selected tab in vertical tab list
  framesPage: number,
  totalFramePages: number,
  framesFilters: VideoFrameFilters,
  frameFiltersShown: boolean,
  videosPage: number,
  pageFrameIds: number[]
}

export const initialContentsTabComponentState : ContentsTabComponentState = {
  selectedFrames: [],
  selectedVideos: [],
  selectedTabNumber: 0,
  framesPage: 0,
  totalFramePages: 0,
  framesFilters: new VideoFrameFilters(),
  frameFiltersShown: false,
  videosPage: 0,
  pageFrameIds: []
};