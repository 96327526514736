import { Action } from '@ngrx/store';
import { NotificationType } from 'src/app/models/notification';

export enum ENotificationsActions {
	ShowNotification = '[Notifications] Show',
  HideNotification = '[Notifications] Hide '
}

export class ShowNotification implements Action {
	public readonly type = ENotificationsActions.ShowNotification;
	constructor ( public text: string, public typeOfNotification: NotificationType ) {}
}

export class HideNotification implements Action {
	public readonly type = ENotificationsActions.HideNotification;
	constructor ( public id: string ) {}
}

export type NotificationsActions = ShowNotification | HideNotification;