import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { switchMap, concatMap, map } from 'rxjs/operators';
import { VideoFrameService } from 'src/app/services/video_frame.service';
import { VideoFrame } from 'src/app/models/video_frame';
import { ECollectionActions, LoadSuccess, Update, UpdateSuccess, ECollectionItemType, LoadPage, Load } from '../actions/collection.actions';
import { FramesPageLoadSuccess } from '../actions/contents-tab-component.actions';

@Injectable()
export class FramesEffects {
	constructor(
		private framesService: VideoFrameService,
		private actions$: Actions
	) {}

	@Effect()
	getFrames$ = this.actions$.pipe(
		ofType<Load<VideoFrame>>(ECollectionActions.Load),
		switchMap(() => this.framesService.getFrames()),
		switchMap((frames: VideoFrame[]) => [
			new LoadSuccess<VideoFrame>(frames, ECollectionItemType.VideoFrame)
		])
	)
	
	@Effect()
	getFramesPage$ = this.actions$.pipe(		
		ofType<LoadPage<VideoFrame>>(ECollectionActions.LoadPage),
		switchMap((action) => {
			return this.framesService.getFramesByFilters(
				action.filters,
				[{ name: action.sort, order: action.sortOrder }],
				action.limit,
				action.offset,
				action.search)
			.pipe(map(frames => ({ frames, action })))
		}),
		switchMap(({frames, action}) => [
			new LoadSuccess<VideoFrame>(frames.data, ECollectionItemType.VideoFrame),
			new FramesPageLoadSuccess(frames.metadata, frames.data)
		])
	)
	
	@Effect()
	updateFrame$ = this.actions$.pipe(
		ofType<Update<VideoFrame>>(ECollectionActions.Update),
		switchMap((action) => this.framesService.updateFrame(action.item)),
		switchMap((frame: VideoFrame) => [ new UpdateSuccess(frame, ECollectionItemType.VideoFrame) ])
  )
}