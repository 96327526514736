import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import ObjectOntologyClass from '../../models/object_ontology_class';
import { OntologyService } from 'src/app/services/ontology.service';
import { Load, ECollectionActions, LoadSuccess, ECollectionItemType } from '../actions/collection.actions';
import * as _ from 'lodash';

// TODO: rewrite to more optimal version
const getOntologyClassPath = (node: ObjectOntologyClass, state: Record<number, ObjectOntologyClass>) : string[] => {
	if (!node) {
		return [];
	}
	if (node.parent_id < 0) {
		return [ node.name ];
	}

	const parent = state[node.parent_id];
	return [...getOntologyClassPath(parent, state), node.name];
}

const fillOntologyPathes = (allNodes: ObjectOntologyClass[]) => {
	const roots = allNodes.filter(node => node.parent_id < 0);
	const dict = _.keyBy(allNodes, 'id');

	const allParentIds = _.uniq(allNodes.map(node => node.parent_id));
	const leaves = allNodes.filter(node => !allParentIds.includes(node.id));

	for (const node of allNodes) {
		node.isLeaf = false;
	}
	for (const node of leaves) {
		node.isLeaf = true;
		getOntologyPathForNode(node, dict);
	}
}

const getOntologyPathForNode = (node: ObjectOntologyClass, allNodes: Record<number, ObjectOntologyClass>) : string[] => {
	if (node.path) {
		return node.path;
	}

	if (node.parent_id < 0) {
		return [ node.name ];
	}
	
	const parent = allNodes[node.parent_id];
	if (!parent) {
		return [ node.name ];
	}

	const parentPath = getOntologyPathForNode(parent, allNodes);
	const path = [ ... parentPath, node.name ];
	node.path = path;

	return path;
}

@Injectable()
export class OntologyEffects {
	constructor(
		private ontologyService: OntologyService,
		private actions$: Actions
	) {}

	@Effect()
	getOntology$ = this.actions$.pipe(
		ofType<Load<ObjectOntologyClass>>(ECollectionActions.Load),
		switchMap(() => this.ontologyService.getOntology()),
		switchMap((ontology: ObjectOntologyClass[]) => {
			fillOntologyPathes(ontology);
			return of(new LoadSuccess<ObjectOntologyClass>(ontology, ECollectionItemType.Ontology));
		})
	)
}