// config.js

export const AppConfiguration = {
    isProduction: false,
    serverUrl: 'https://api.tpshawk.com/backend',
    aws_cognito_region: 'eu-west-1',
    aws_user_pools_id: 'eu-west-1_YokwypvWH',
    aws_user_pools_web_client_id: '6r10smq9jk4g90oqna8vh2m6kf',
    oauth: {}
};
