import { Configuration } from 'src/app/models/configuration';

export const initialConfigurationState : Configuration = {
	isProduction: false,
	serverUrl: null,
	version: null,
	aws_cognito_region: null,
	aws_user_pools_id: null,
	aws_user_pools_web_client_id: null,
	oauth: {}
};