import { Action } from '@ngrx/store';

export enum EAnnotatorComponentActions {
  ChangeJob = '[AnnotatorComponent] ChangeJob',
  ChangeFrameIndex = '[AnnotatorComponent] ChangeFrameIndex'
}

export class ChangeJob implements Action {
  public readonly type = EAnnotatorComponentActions.ChangeJob;
  constructor (public jobId: number) {}
}

export class ChangeFrameIndex implements Action {
  public readonly type = EAnnotatorComponentActions.ChangeFrameIndex;
  constructor (public frameIndex: number) {}
}

export type AnnotatorComponentActions = ChangeJob | ChangeFrameIndex;