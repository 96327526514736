import { AppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { Basket, BasketType } from 'src/app/models/basket';
import * as _ from 'lodash';
import { VideoFrame } from 'src/app/models/video_frame';
import { CollectionSelectors, FramesSelectors, OntologySelectors } from './collection.selectors';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';

const getBaskets = (state: AppState) => state.baskets;
const getVideoFrameBaskets = (state: AppState) =>
  _.pickBy(state.baskets, (v : Basket, k) => v.type === BasketType.VideoFrames);

const getClassBaskets = (state: AppState) =>
  _.pickBy(state.baskets, (v : Basket, k) => v.type === BasketType.Classes);

export class BasketsSelectors extends CollectionSelectors<Basket> {
  private constructor() {
    super(getBaskets);
  }

  public static instance : BasketsSelectors = new BasketsSelectors();

  selectVideoFrameBaskets = createSelector(
    getVideoFrameBaskets,
    baskets => baskets
  );

  selectClassBaskets = createSelector(
    getClassBaskets,
    baskets => baskets
  );
  
  selectClassBasketsList = createSelector(
    getClassBaskets,
    baskets => _.values(baskets)
  );

  selectVideoFrameBasketsList = createSelector(
    getVideoFrameBaskets,
    baskets => _.values(baskets)
  );

  selectBasketsWithFramesDict = createSelector(
    FramesSelectors.selectDict,
    this.selectVideoFrameBaskets,
    (frames: Record<number, VideoFrame>, baskets : Record<number, Basket>) =>
      _.mapValues(baskets, (basket) => ({
        ...basket,
        videoFrames: basket.videoFrameIds ? basket.videoFrameIds.map(frameId => frames[frameId]) : []
      }))
  )

  selectBasketsWithClassesDict = createSelector(
    OntologySelectors.selectDict,
    this.selectClassBaskets,
    (classes: Record<number, ObjectOntologyClass>, baskets : Record<number, Basket>) =>
      _.mapValues(baskets, (basket) => ({
        ...basket,
        classes: basket.classIds ? basket.classIds.map(classId => classes[classId]) : []
      }))
  )
} 