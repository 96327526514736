import { AnnotatorComponentActions, EAnnotatorComponentActions } from '../actions/annotator-component.actions'
import { initialAnnotatorComponentState, AnnotatorComponentState } from '../state/annotator-component.state';

export function annotatorComponentReducers( 
	state = initialAnnotatorComponentState,
	action: AnnotatorComponentActions
) : AnnotatorComponentState {
	switch (action.type) {
    case EAnnotatorComponentActions.ChangeJob:
      return {
        ...state,
        jobId: action.jobId
			}
		case EAnnotatorComponentActions.ChangeFrameIndex:
			return {
				...state,
				currentFrameIndex: action.frameIndex
			}
		default:
			return state;
	}
}