import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import Tag           from 'src/app/models/tag';
import UploadedFile  from 'src/app/models/uploaded_file';

import { TagService }      from 'src/app/services/tag.service';


import * as _ from 'lodash';

@Component({
  selector: 'debug-tags',
  templateUrl: './debug-tags.component.html',
  styleUrls: ['./debug-tags.component.scss']
})

export class DebugTagsComponent implements OnInit {
  prefix:    string;
  count:     number  = 10;




  palette = [
    { code:'#f00', color: (255 * 256 * 256 +   0 * 256 +   0)  },
    { code:'#0f0', color: (  0 * 256 * 256 + 255 * 256 +   0)  },
    { code:'#00f', color: (  0 * 256 * 256 +   0 * 256 + 255)  },

    { code:'#ff0', color: (255 * 256 * 256 + 255 * 256 +   0)  },
    { code:'#0ff', color: (  0 * 256 * 256 + 255 * 256 + 255)  },
    { code:'#f0f', color: (255 * 256 * 256 +   0 * 256 + 255)  }
  ];
  color = this.palette[0];
  

  autoRefresh: boolean = false;

  tagToAdd : string;

  uploadedFiles: UploadedFile[] = [];


  tags: Tag[] = [];
  constructor(
    private tagService   : TagService,
  ) { }

  getColorCode(color : number) : string {
    return "#" + (color & 0x00FFFFFF).toString(16).padStart(6, '0')
  }

  ngOnInit(): void {
    this.refreshTags();
  }

  refreshTags() : void {
    this.tagService.getTags(this.prefix, this.count)
    .subscribe(data => {
      this.tags = data;
      console.log(data);
    });
  }

  onPrefixChange(event) {
    this.prefix = event.target.value;
    if (this.autoRefresh) {
       this.refreshTags();
    }
  }
  onCountChange(event) {
    this.count = event.target.value;
    if (this.autoRefresh) {
       this.refreshTags();
    }
  }

  onAutorefreshCheckChanged(event : any) {
    this.autoRefresh = event.target.checked;
    if (this.autoRefresh) {
       this.refreshTags();
    }
  }

  onTagSelected(tag_id : number) {
    this.tagService.getVideos(tag_id)
    .subscribe(data => {
      this.uploadedFiles = data;
      console.log(data);
    });
  }


  /* Adding tags */
 onTagToAddChange(event: any) : void {
   this.tagToAdd = event.target.value;
 }

 setColor(newColor : any) : void {
   this.color = newColor;
 }

 addTag() : void {
    this.tagService.createTag(this.tagToAdd, this.color.color).then( (data) => this.refreshTags());
 }


}
