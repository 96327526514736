import { initialJobsState } from '../state/app.state'
import { JobsActions, EJobsActions, UpdateJob } from '../actions/jobs.actions'
import * as _ from 'lodash';
import { Job } from 'src/app/models/job';

export function jobsReducers( 
	state = initialJobsState,
	action: JobsActions
) : Record<number, Job> {
	switch (action.type) {
		case EJobsActions.LoadJobsSuccess:
			return {
				... state,
				... _.keyBy(action.jobs, 'id')
			};
    case EJobsActions.CreateJobSuccess:
			if (action.job && action.job.id) {
				const newEntry = {};
				newEntry[action.job.id] = action.job;
				return {
					...state,
					...newEntry
				};
			}
			else {
				return state;
			}
		case EJobsActions.UpdateJob:
			const dataToUpdate = {};
			const job = (action as UpdateJob).job;
			const storedJob = state[job.id];
			dataToUpdate[job.id] = {
				...storedJob,
				...job
			};

			return {
				...state,
				...dataToUpdate
			}
		default:
			return state;
	}
}