import { Component, OnInit} from '@angular/core';

import { Basket }             from 'src/app/models/basket';

import { BasketService }   from 'src/app/services/basket.service';
import { VideoFrame }      from 'src/app/models/video_frame';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';
import UploadedFile        from 'src/app/models/uploaded_file';

import { ActionsService}       from 'src/app/services/actions.service';

import { Actor,  ActorTarget}  from 'src/app/models/actor';

import * as _ from 'lodash';

@Component({
  selector: 'debug-baskets',
  templateUrl: './debug-baskets.component.html',
  styleUrls: ['./debug-baskets.component.scss']
})

export class DebugBasketsComponent implements OnInit {

  baskets        : Basket[] = [];
  ontologyClasses: ObjectOntologyClass[] = [];
  videoFrames    : VideoFrame[];
  videos         : UploadedFile[];


  actors        : Actor[] = [];
  selectedActor : number = 0;
  checkedRows   : Basket[] = [];

  reqLimit  : number = 50;
  reqOffset : number = 0;
  

  constructor(
    private actionsService    : ActionsService,
    private basketsService    : BasketService
    
  ) { }


  ngOnInit(): void {
    this.refresh();
  }
  
  actorSelected(event: any): void
  {
    console.log(event);
    this.selectedActor = event.target.selectedIndex - 1;
  }


  onCheckChanged(basket: Basket, event: any) : void {
    if (event.target.checked) {
      this.checkedRows.push(basket);
    }
    else {
      _.remove(this.checkedRows, (f) => f === basket);
    }
  }


  async startActionCheckedBaskets() {
    console.log(this.selectedActor);
    if (this.selectedActor < 0)
      return;

    let actor = this.actors[this.selectedActor];
    this.actionsService
    .startBasketActions(actor, this.checkedRows)
    .subscribe((res) => null, (error) => {
      console.error(error);
      alert(error.message);
    })
    this.refresh();
    this.checkedRows = [];
  }

  refresh() {
    let filters : any [] = [];



    this.basketsService.getBasketsEx(
      filters,
      [ 
        {name: 'id'          , order: this.sort.id    }, 
        {name: 'name'        , order: this.sort.name  },
        {name: 'user'        , order: this.sort.user  }
      ], this.reqLimit, this.reqOffset
    )
    .subscribe(data => {
      this.baskets = data;
      console.log(data);
    });


    this.actionsService.getActors(ActorTarget.Basket, true)
    .subscribe(data => {
      this.actors = data;
      if (data.length > 0) {
        this.selectedActor = -1;
      }
      console.log(data);
    });
  }

  basketSelected(idx:number) : void {
    let id = this.baskets[idx].id;

    this.basketsService.getFrames(id)
    .subscribe(vdata => {
      this.videoFrames = vdata;
      console.log(vdata);
    });

    this.basketsService.getObjectClasses(id)
    .subscribe(odata => {
      this.ontologyClasses = odata;
      console.log(odata);
    });

    this.basketsService.getVideos(id)
    .subscribe(vdata => {
      this.videos = vdata;
      console.log(vdata);
    });
  }




  onLimitChanged(event : any) {
    this.reqLimit = event.target.value;
  }

  onOffsetChanged(event : any)
  {
    this.reqOffset = event.target.value;
  }

  sort : any = {
    id          : 1,
    name        : 1,
    user        : 1
  }
  
  onIdSort() : void
  {
    this.sort.id = (this.sort.id + 1) % 3;
  }

  onNameSort()
  {
    this.sort.name = (this.sort.name + 1) % 3;
  }

  onUserSort()
  {
    this.sort.user = (this.sort.user + 1) % 3;
  }

}
