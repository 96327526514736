import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { HttpAuthClient } from './http_auth';
import ProxyStat from '../models/proxy_stat';
import ActiveActor from '../models/active_actors';
import { AppState } from '../store/state/app.state';
import { HttpServiceBase } from './http_service_base';

@Injectable({
	providedIn: 'root'
})
export class ActorsProxyService extends HttpServiceBase {
	constructor(
    private httpAuth: HttpAuthClient,
    protected store : Store<AppState>
  ) {
    super(store);
  }

  getActiveActors() : Observable<ActiveActor[]> {
    // @ts-ignore
    return this.httpAuth.get<Actor[]>(`${this.serverUrl}/api/nodes`);
  }

  getProxyStats(node : string) : Observable<ProxyStat[]> {
    // @ts-ignore
    return this.httpAuth.get<ProxyStat[]>(`${this.serverUrl}/api/data?target=0`);
  }
}