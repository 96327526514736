import { createSelector, props } from "@ngrx/store";
import { label } from "aws-amplify";
import { FrameLabel } from "src/app/models/frame_label";
import { AppState } from "../state/app.state";

const getFrameLabels = (state: AppState) =>
  Object.values(state.frameLabels);

export const selectLabelsForFrame = createSelector(
  getFrameLabels,
  (labels : FrameLabel[], props: { frameID : number }) =>
    labels.filter(l => l.frame === props.frameID)
)

export const selectFrameLabels = createSelector(
  getFrameLabels,
  (labels: FrameLabel[]) => labels
)