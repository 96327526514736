import { AppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { Basket } from 'src/app/models/basket';
import { VideoFrame } from 'src/app/models/video_frame';
import { BasketsSelectors } from './baskets.selectors';
import { FramesSelectors, OntologySelectors } from './collection.selectors';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';

const getFrameBasketId = (state: AppState) => state.basketComponent.frameBasketId;
const getClassBasketId = (state: AppState) => state.basketComponent.classBasketId;

export const selectFrameBasketId = createSelector(
  getFrameBasketId,
  (id : number) => id);

export const selectFrameBasket = createSelector(
  BasketsSelectors.instance.selectVideoFrameBaskets,
  getFrameBasketId,
  (baskets : Record<number, Basket>, basketId: number) =>
    basketId === null
    ? null
    : baskets[basketId]
);

export const selectFrameBasketFrames = createSelector(
  selectFrameBasket,
  FramesSelectors.selectDict,
  (basket: Basket, frames: Record<number, VideoFrame>) =>
    basket ? basket.videoFrameIds.map(frameId => frames[frameId]) : []
);

export const selectClassBasketId = createSelector(
  getClassBasketId,
  (id : number) => id
);

export const selectClassBasket = createSelector(
  BasketsSelectors.instance.selectClassBaskets,
  getClassBasketId,
  (baskets : Record<number, Basket>, basketId: number) =>
    basketId === null
    ? null
    : baskets[basketId]
);

export const selectClassBasketClasses = createSelector(
  selectClassBasket,
  OntologySelectors.selectDict,
  (basket: Basket, classes: Record<number, ObjectOntologyClass>) =>
    basket ? basket.classIds.map(classId => classes[classId]) : []
);