import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { VideoFrame, VideoFrameStatus } from 'src/app/models/video_frame';
import UploadedFile from 'src/app/models/uploaded_file';
import { VideoFrameService } from 'src/app/services/video_frame.service';
import Tag from 'src/app/models/tag';
import { TagService } from 'src/app/services/tag.service';
import { Auth } from 'aws-amplify';
import { ShowNotification } from 'src/app/store/actions/notifications.actions';
import { NotificationType } from 'src/app/models/notification';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import * as _ from 'lodash';

@Component({
  selector: 'cutter-actions',
  templateUrl: './cutter-actions.component.html',
  styleUrls: ['./cutter-actions.component.scss']
})
export class CutterActionsComponent implements OnInit {
  @Input() video: UploadedFile;
  @Input() frames: VideoFrame[];
  @Input() videoElementContainer: { el };

  @Output("onDelete") onDelete = new EventEmitter();

  @ViewChild('tags', {static: false}) tagsInput: ElementRef;
  @ViewChild('comments', {static: false}) commentsInput: ElementRef;

  availableTags : Tag[] = [];
  selectedTags: Tag[] = [];

  dropdownSettings = {
    singleSelection: false,
    text: "Select tags",
    enableSearchFilter: true,
    addNewItemOnFilter: true,
    labelKey: "name",
    searchBy: ['name']
  };

  constructor(
    private videoFrameService: VideoFrameService,
    private tagService: TagService,
    private store: Store<AppState>) { }

  ngOnInit() {
    this.tagService.getTags('', 100).subscribe(tags => {
      this.availableTags = tags;
    });
  }

  async cutNewFrame() {
    const videoPlayer = this.videoElementContainer.el;

    const canvas = document.createElement('canvas');
    canvas.height = videoPlayer.videoHeight;
    canvas.width = videoPlayer.videoWidth;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
    const base64Img = canvas.toDataURL();

    const newFrame : VideoFrame = {
      id: null,
      name: _.uniqueId(this.video.originalName),
      user_id: null,
      video_id : this.video.id,
      timestamp: null, // TODO: fill with value
      url : base64Img,
      thumbnail_url : base64Img,
      height: videoPlayer.videoHeight,
      width: videoPlayer.videoWidth,
      deleted: false,
      tags: this.selectedTags,
      comments: this.commentsInput.nativeElement.value,
      status: VideoFrameStatus.None,
      created_by: null, 
      creation_date: null,
      video_date: null
    };

    let updatedFrame = null;
    try {
      updatedFrame = await this.videoFrameService.createFrame(newFrame);
    }
    catch {
      this.store.dispatch(new ShowNotification("Failed to save a frame.", NotificationType.Error));
      return;
    }

    if (updatedFrame) {
      newFrame.id = updatedFrame.id;
      newFrame.creation_date = updatedFrame.creation_date;
      newFrame.video_date = updatedFrame.video_date;
      newFrame.url = updatedFrame.url;
      newFrame.thumbnail_url = updatedFrame.thumbnail_url;
    }

    this.frames.push(newFrame);
  }

  deleteFrames() {
    this.onDelete.emit();
  }

  async onAddTag(value: string): Promise<void> {
    const newTag: Tag = {id: null, name: value, color: '0', user_id: null, popualrity: 0};
    this.selectedTags.push(newTag);

    const currentUser = await Auth.currentAuthenticatedUser();
    newTag.user_id = currentUser.username;
  }

}
