import { Configuration } from '../../models/configuration';
import ObjectOntologyClass from '../../models/object_ontology_class';
import { initialConfigurationState } from './configuration.state';
import { initialBasketComponentState, BasketComponentState } from './basket-component.state';
import { initialAnnotatorComponentState, AnnotatorComponentState } from './annotator-component.state';
import { Basket } from 'src/app/models/basket';
import { Job } from 'src/app/models/job';
import { VideoFrame } from 'src/app/models/video_frame';
import { Notification } from 'src/app/models/notification';
import { FrameLabel } from 'src/app/models/frame_label';
import UploadedFile from 'src/app/models/uploaded_file';
import { ContentsTabComponentState, initialContentsTabComponentState } from './contents-tab-component.state';
import { BackendConfiguration } from 'src/app/models/backend_configuration';

export interface AppState {
	configuration: Configuration,
	backendConfiguration: BackendConfiguration,
	selectedTabNumber: number,
	ontology: Record<number, ObjectOntologyClass>,
	baskets : Record<number, Basket>,
	videos  : Record<number, UploadedFile>,
	frames  : Record<number, VideoFrame>,
	jobs : Record<number, Job>,
	frameLabels : Record<number, FrameLabel>,
	basketComponent: BasketComponentState,
	annotatorComponent: AnnotatorComponentState,
	contentsTabComponent: ContentsTabComponentState,
	notifications: Record<string, Notification>
};

export const initialBackendConfigurationState : BackendConfiguration = {
	backendAvailable: true,
	backendVersion : null,
  dbVersion      : null
};
export const initialJobsState 				: Record<number, Job> = {};
export const initialFrameLabelsState  : Record<number, FrameLabel> = {};
export const initialNotificationsState:	Record<string, Notification> = {};

export const initialAppState : AppState = {
	configuration : initialConfigurationState,
	backendConfiguration : initialBackendConfigurationState,
	selectedTabNumber: 1,
	ontology: {},
	baskets: {},
	videos: {},
	frames: {},
	jobs: initialJobsState,
	frameLabels: initialFrameLabelsState,
	notifications: initialNotificationsState,
	basketComponent: initialBasketComponentState,
	annotatorComponent: initialAnnotatorComponentState,
	contentsTabComponent: initialContentsTabComponentState
};

export const getInitialState = () => {
	return initialAppState;
}