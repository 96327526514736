import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';
import { Basket } from 'src/app/models/basket';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { ChangeFrameBasket, ChangeClassBasket } from 'src/app/store/actions/basket-component.actions';
import { selectFrameBasketId, selectClassBasketId } from 'src/app/store/selectors/basket-component.selectors';
import * as _ from 'lodash';
import { BasketsSelectors } from 'src/app/store/selectors/baskets.selectors';
import { OntologySelectors } from 'src/app/store/selectors/collection.selectors';

@Component({
  selector: 'basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit, OnDestroy {
  baskets: Basket[];
  classBaskets: Basket[];
  allClasses: Record<number, ObjectOntologyClass>;
  selectedFrameBasketId: number;
  selectedClassBasketId: number;

  private baskets$ : Observable<Record<number, Basket>> = this.store.pipe(select(BasketsSelectors.instance.selectBasketsWithFramesDict));
  private classBaskets$ : Observable<Record<number, Basket>> = this.store.pipe(select(BasketsSelectors.instance.selectBasketsWithClassesDict));
  private allClasses$: Observable<Record<number, ObjectOntologyClass>> = this.store.pipe(select(OntologySelectors.selectDict));
  private selectedFrameBasketId$ : Observable<number> = this.store.pipe(select(selectFrameBasketId));
  private selectedClassBasketId$ : Observable<number> = this.store.pipe(select(selectClassBasketId));
  private subscriptions: Subscription[] = [];
  
  constructor(
    private store: Store<AppState>) { }

  ngOnInit() {
    this.subscriptions.push(
      this.baskets$.subscribe(baskets => {
        this.baskets = _.values(baskets);
        console.log("frames-baskets ", baskets);
      })
    );
    this.subscriptions.push(
      this.classBaskets$.subscribe(baskets => {
        this.classBaskets = _.values(baskets);
        console.log("class-baskets ", baskets);
      })
    );
    this.subscriptions.push(
      this.allClasses$.subscribe(selectedClasses => {
        this.allClasses = selectedClasses;
      })
    );
    this.subscriptions.push(
      this.selectedFrameBasketId$.subscribe(selectedBasketId => {
        this.selectedFrameBasketId = selectedBasketId;
      })
    );
    this.subscriptions.push(
      this.selectedClassBasketId$.subscribe(selectedBasketId => {
        this.selectedClassBasketId = selectedBasketId;
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  onSelectedFrameBasketChanged(basket: Basket) {
    this.store.dispatch(new ChangeFrameBasket(basket.id));
  }

  onSelectedClassBasketChanged(basket: Basket) {
    this.store.dispatch(new ChangeClassBasket(basket.id));
  }

  getClassPathString(oclass: ObjectOntologyClass): string {
    const path = oclass.path;
    if (!path || path.length <= 2) {
      return "";
    }
    else {
      return path.slice(0, path.length - 2).join('/');
    }
  }

  getClassParentName(oclass: ObjectOntologyClass): string {
    if (oclass.parent_id < 0) {
      return "";
    }
    else {
      const parent = this.allClasses[oclass.parent_id];
      return parent.name;
    }
  }

}
