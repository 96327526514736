import { SortOrder } from "./sort_order";
import { VideoFrameStatus } from "./video_frame";

export enum VideoFrameSortColumn {
  Id = 'id',
  CreationDate = 'creation_date',
  VideoId = 'video_id',
  VideoDate = 'video_date' 
}

export class VideoFrameFilters {
  sortColumn: VideoFrameSortColumn = VideoFrameSortColumn.VideoDate;
  sortOrder: SortOrder = SortOrder.Desc;
  searchQuery?: string = null
  statusFilter?: VideoFrameStatus = null;
  tagFilter?: string = null;
  creationDateFilter?: string = null;
}