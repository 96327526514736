import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap} from 'rxjs/operators';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EBackendConfigurationActions, LoadBackendConfiguration, LoadBackendConfigurationSuccess } from '../actions/backend-configuration.actions';
import { BackendConfiguration } from '../../models/backend_configuration';

@Injectable()
export class BackendConfigurationEffects {
	constructor(
		private BackendConfigurationService: ConfigurationService,
		private actions$: Actions
	) {}

	@Effect()
	getBackendConfiguration$ = this.actions$.pipe(
		ofType<LoadBackendConfiguration>(EBackendConfigurationActions.Load),
		switchMap(() => this.BackendConfigurationService.getBackendConfiguration()),
		switchMap((BackendConfiguration: BackendConfiguration) => of(new LoadBackendConfigurationSuccess(BackendConfiguration)))
	)
}