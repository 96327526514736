import { Component, OnInit, EventEmitter, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import UIkit from 'uikit';
import { ContentsItem, ConstentsItemType } from 'src/app/models/contents_item';

import { utilsAuth }         from 'src/app/services/utils_auth';

import UploadedFile       from 'src/app/models/uploaded_file';
import { VideoFrame }     from 'src/app/models/video_frame';
import * as _ from 'lodash';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { selectSelectedFrames, selectSelectedVideos, selectFramesOfSelectedVideos, selectSelectedTabNumber, selectPageFrames, selectFramesPageNumber, selectTotalFramePages, selectCurrentFrameFilters } from 'src/app/store/selectors/contents-tab-component.selectors';
import { SelectedFramesChanged, SelectedVideosChanged, NavigateToTab, NavigateToFramesPage, FramesFiltersChanged } from 'src/app/store/actions/contents-tab-component.actions';
import FileToUpload from 'src/app/models/file_to_upload';
import { Job, JobStatus } from 'src/app/models/job';
import { selectJob } from 'src/app/store/selectors/annotator-component.selectors';
import { UpdateJob } from 'src/app/store/actions/jobs.actions';
import { VideosSelectors, FramesSelectors } from 'src/app/store/selectors/collection.selectors';
import { ECollectionItemType, Load, LoadPage } from 'src/app/store/actions/collection.actions';
import { selectConfiguration } from 'src/app/store/selectors/configuration.selector';
import { Auth } from 'aws-amplify';
import { VideoFrameFilters, VideoFrameSortColumn } from 'src/app/models/video_frame_filters';
import * as moment from 'moment';
import { freemem } from 'os';

@Component({
  selector: 'contents-tab',
  templateUrl: './contents-tab.component.html',
  styleUrls: ['./contents-tab.component.scss']
})

export class ContentsTabComponent implements OnInit, OnDestroy {
  @ViewChild('fileInput', {static: false}) fileInput : ElementRef;
  filesToUpload : FileToUpload[];
  groups: string[] = [];
  jobForAnnotator: Job;
  firstNavigationHappened: boolean = false;

  selectedTabNumber: number = 0;

  private subscriptions: Subscription[] = [];
  private jobForAnnotator$ : Observable<Job> = this.store.pipe(select(selectJob));
  videos$ : Observable<UploadedFile[]> = this.store.pipe(select(VideosSelectors.selectItems));
  frames$ : Observable<VideoFrame[]> = this.store.pipe(select(selectPageFrames));
  checkedFrames$ : Observable<VideoFrame[]> = this.store.pipe(select(selectSelectedFrames));
  checkedVideos$ : Observable<UploadedFile[]> = this.store.pipe(select(selectSelectedVideos));
  framesOfCheckedVideos$ : Observable<VideoFrame[]> = this.store.pipe(select(selectFramesOfSelectedVideos));
  selectedTabNumber$ : Observable<number> = this.store.pipe(select(selectSelectedTabNumber));
  framesPageNum$ : Observable<number> = this.store.pipe(select(selectFramesPageNumber));
  totalFramePages$ : Observable<number> = this.store.pipe(select(selectTotalFramePages));
  frameFilters$ : Observable<VideoFrameFilters> = this.store.pipe(select(selectCurrentFrameFilters));
  videoFiles: UploadedFile[] = [];
  videoItems: ContentsItem[] = [];
  checkedVideoFiles : UploadedFile[] = [];
  frames: VideoFrame[] = [];
  frameItems: ContentsItem[] = [];
  checkedFrames: VideoFrame[] = [];
  frameToAnnotate: VideoFrame = null;
  framesPageNum: number = 0;
  totalFramePages: number = 0;
  frameFilters: VideoFrameFilters = new VideoFrameFilters();

  checkedVideosFrames: VideoFrame[] = []; // frames of checked videos
  cutterVideoElementContainer = { el: null };
  onFramesDelete = new EventEmitter();

  public constructor(
    private store : Store<AppState>
    ) {}

  ngOnInit() {
    let groupsLoaded = false;
    const getGroups = async () => {
      if (!groupsLoaded) {
        let currentSession = null;
        try {
          currentSession = await Auth.currentSession();
        }
        catch {}

        if (currentSession) {
          groupsLoaded = true;
          let accessTokenPayload =  currentSession.getAccessToken().decodePayload(); 
          this.groups = accessTokenPayload['cognito:groups'];
        }
      }
    }
    getGroups().then(() => {
      if (!groupsLoaded) {
        const int = setInterval(async () => {
          await getGroups();
          if (groupsLoaded) {
            clearInterval(int);
          }
        }, 100)
      }
    });

    this.subscriptions.push(
      this.videos$.subscribe(data => {
        this.videoFiles = data;
        this.refreshVideoItems();
      })
    );

    this.subscriptions.push(
      this.jobForAnnotator$.subscribe((job: Job) => {
        this.jobForAnnotator = job;
      })
    );

    this.subscriptions.push(
      this.framesPageNum$.subscribe((num: number) => {
        this.framesPageNum = num;
      })
    );

    this.subscriptions.push(
      this.totalFramePages$.subscribe((total: number) => {
        this.totalFramePages = total;
      })
    );

    this.subscriptions.push(
      this.frameFilters$.subscribe((filters: VideoFrameFilters) => {
        this.frameFilters = filters;
      })
    );

    this.subscriptions.push(this.checkedVideos$.subscribe(data => {
      this.checkedVideoFiles = data;
      this.updateVideoItems();
    }));

    this.subscriptions.push(
      this.frames$.subscribe(data => {
        this.frames = data;
        if (this.groups.length > 0) {
          this.updateSelectedTabIfNeeded();
        }
        this.refreshFrameItems();
      })
    );

    this.subscriptions.push(this.checkedFrames$.subscribe(data => {
      this.checkedFrames = data;
      this.updateFrameItems();
    }));

    this.subscriptions.push(this.framesOfCheckedVideos$.subscribe(data => {
      this.checkedVideosFrames = data;
    }));

    this.subscriptions.push(
      this.selectedTabNumber$.subscribe(data => {
        this.selectedTabNumber = data;
      })
    );

    // utilsAuth.checkOrRegister();
    this.store.dispatch(new Load<VideoFrame>(
      ECollectionItemType.VideoFrame
    ));
    this.store.dispatch(new NavigateToFramesPage(0));

    utilsAuth.getGroups().then(data => {
      this.groups = data;
      if (this.frames.length > 0) {
        this.updateSelectedTabIfNeeded();
      }
    });
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    this.frameItems.length = 0;
    this.videoItems.length = 0;
  }

  onVideosCheckChanged(checkedItems: ContentsItem[]) {
    console.log('onVideosCheckChanged', checkedItems);
    this.store.dispatch(new SelectedVideosChanged(checkedItems.map(item => item.id)));
  }

  onFramesCheckChanged(checkedItems: ContentsItem[]) {
    console.log('onFramesCheckChanged', checkedItems);
    this.store.dispatch(new SelectedFramesChanged(checkedItems.map(item => item.id)));
  }

  switchToTab(tabNumber: number) {
    this.store.dispatch(new NavigateToTab(tabNumber));
  }

  onFramesPageChanged(pageNum: number) {
    this.store.dispatch(new NavigateToFramesPage(pageNum));
  }

  refreshVideoItems() : void {
    this.videoItems = this.videoFiles.map(video => {

      let video_thumbnail = video.thumbnail_url;
      if (!video_thumbnail) {
        if (video.contentType.endsWith("zip")) {
          video_thumbnail = 'assets/images/database.svg'
        } else {
          video_thumbnail = 'assets/images/video-player.svg'
        }
      }
      return ({
        id: video.id,
        title: video.originalName,
        url: video.url,
        thumbnail_url       : video_thumbnail,
        thumbnail_hover_url : !!video.thumbnail_video_url ? video.thumbnail_video_url : video.url,
        type: ConstentsItemType.Video,
        tags: video.tags,
        checked: this.checkedVideoFiles.findIndex(v => v.id === video.id) >= 0,
        group_key: moment(video.upload_date).format('YYYY-MM-DD')
      })
    });
    console.log('contents-tab: refresh: videoItems:', this.videoItems.length);
  }

  updateSelectedTabIfNeeded() {
    if (!this.firstNavigationHappened) {
      this.firstNavigationHappened = true;
      if (this.groups.includes('annotator') && !this.groups.includes('manager') && !this.groups.includes('superuser')) {
        if (this.selectedTabNumber != 4) {
          this.store.dispatch(new NavigateToTab(4)); // Jobs tab
        }
      }
    }
  }

  updateVideoItems(): void {
    const itemsToBeChecked =
      this.checkedVideoFiles.map(v => this.videoItems.find(item => item.id == v.id));
    for (let item of this.videoItems) {
      item.checked = itemsToBeChecked.includes(item);
    }
  }

  refreshFrameItems() : void {
    this.frameItems = this.frames.map(frame => ({
      id: frame.id,
      title: null,
      url: frame.url,
      thumbnail_url       : !!frame.thumbnail_url ? frame.thumbnail_url : frame.url,
      thumbnail_hover_url : !!frame.thumbnail_url ? frame.thumbnail_url : frame.url,
      type: ConstentsItemType.Frame,
      checked: this.checkedFrames.findIndex(f => f.id === frame.id) >= 0,
      status: frame.status,
      group_key: this.getFrameGroupKey(frame)
    }));
    console.log('contents-tab: refresh: frameItems', this.frameItems.length);
  }

  updateFrameItems(): void {
    const itemsToBeChecked =
      this.checkedFrames.map(v => this.frameItems.find(item => item.id == v.id));
    for (let item of this.frameItems) {
      item.checked = itemsToBeChecked.includes(item);
    }
  }

  onAnnotate() {
    if (this.checkedFrames.length > 0) {
      this.frameToAnnotate = this.checkedFrames[0]
      this.selectedTabNumber = 3;
    }
    else {
      console.warn('No frames to annotate');
    }
  }

  deleteFrames() {
    this.onFramesDelete.emit(null);
  }

  async onSelectedFileChanged(e) {
    console.log('onSelectedFileChanged', e, e.currentTarget.files);
    const selectedFiles = e.currentTarget.files;

    if (!selectedFiles) {
      alert('File to upload is not selected.');
      return;
    }

    // console.log('old files to upload:', this.filesToUpload);
    const newFilesToUpload = Array.from(selectedFiles).map((file : File) => {
      let res = new FileToUpload();        
      res.file =  file;
      res.date = new Date();
      res.comment = null as string;
      res.tags = [];
      res.progress = 0;
      return res;
    });
    const updatedFilesToUpload = [];
    if (this.filesToUpload) {
      for(let file of this.filesToUpload) {
        updatedFilesToUpload.push(file);
      }
    }
    for(let file of newFilesToUpload) {
      if (updatedFilesToUpload.findIndex(f => f.file.name == file.file.name && f.file.size === file.file.size) < 0) {
        updatedFilesToUpload.push(file);
      }
    }
    this.filesToUpload = updatedFilesToUpload;
    // console.log('new files to upload:', this.filesToUpload);

    const modal = UIkit.modal('#uploadFilesModal');
    UIkit.util.on(document, 'beforehide', '#uploadFilesModal', () => {
      // this.filesToUpload = [];
      // console.log('Upload modal hide: files:', this.filesToUpload);
      this.fileInput.nativeElement.value = null;
    });
    modal.show();

    return;
  }

  uploadFiles() {
    const input = document.getElementById('file');
    input.click();
  }

  completeJob() {
    const updatedJob = {...this.jobForAnnotator, status: JobStatus.Completed};
    this.store.dispatch(new UpdateJob(updatedJob));
    this.store.dispatch(new NavigateToTab(4)); // Navigate to Jobs tab
  }

  onFrameFiltersChanged(value: VideoFrameFilters) {
    this.store.dispatch(new FramesFiltersChanged(value));
  }

  getFrameGroupKey(frame: VideoFrame) : string | number {
    const keyFromFilters = this.frameFilters.sortColumn;
    switch (keyFromFilters) {
      case VideoFrameSortColumn.Id:
        return frame.id;
      case VideoFrameSortColumn.CreationDate:
        return frame.timestamp;
      case VideoFrameSortColumn.VideoDate:
        return frame.video_date ? moment(frame.video_date).format('YYYY-MM-DD') : null;
      case VideoFrameSortColumn.VideoId:
        return frame.video_id;
      default:
        return frame.id;
    }
  }
}
