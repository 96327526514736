import { AppState } from "../state/app.state";
import { createSelector } from '@ngrx/store';
import { VideoFrame } from 'src/app/models/video_frame';
import UploadedFile from 'src/app/models/uploaded_file';
import { VideosSelectors, FramesSelectors } from './collection.selectors';

const getSelectedFrameIds = (state: AppState) => state.contentsTabComponent.selectedFrames;
const getSelectedVideoIds = (state: AppState) => state.contentsTabComponent.selectedVideos;
const getSelectedTabNumber = (state: AppState) => state.contentsTabComponent.selectedTabNumber;
const getPageFrameIds = (state: AppState) => state.contentsTabComponent.pageFrameIds;
const getFramePageNumber = (state: AppState) => state.contentsTabComponent.framesPage;
const getTotalFramePages = (state: AppState) => state.contentsTabComponent.totalFramePages;
const getFramesFilters = (state: AppState) => state.contentsTabComponent.framesFilters;
const getFrameFiltersShown = (state: AppState) => state.contentsTabComponent.frameFiltersShown;

export const selectSelectedFrames = createSelector(
  FramesSelectors.selectItems,
  getSelectedFrameIds,
  (frames: VideoFrame[], frameIds: number[]) =>
    frames == null || frames.length === 0
    ? []
    : frames.filter(frame => frameIds.includes(frame.id))
)

export const selectSelectedVideos = createSelector(
  VideosSelectors.selectItems,
  getSelectedVideoIds,
  (videos: UploadedFile[], videoIds: number[]) =>
    videos == null || videos.length === 0
    ? []
    : videos.filter(video => videoIds.includes(video.id))
)

export const selectFramesOfSelectedVideos = createSelector(
  selectSelectedVideos,
  FramesSelectors.selectItems,
  getSelectedVideoIds,
  (videos: UploadedFile[], frames: VideoFrame[], videoIds: number[]) => {
    const selectedVideoIds = videos.map(v => v.id); // Frames are still referencing videos by server ids.
    return frames.filter(f => selectedVideoIds.indexOf(f.video_id) >= 0);
  }
)

export const selectSelectedTabNumber = createSelector(
  getSelectedTabNumber,
  (tabNumber : number) => tabNumber
)

export const selectFramesPageNumber = createSelector(
  getFramePageNumber,
  (num) => num
) 

export const selectPageFrames = createSelector(
  getPageFrameIds,
  FramesSelectors.selectDict,
  (frameIDs, frames) => frameIDs.map(id => frames[id])
)

export const selectTotalFramePages = createSelector(
  getTotalFramePages,
  (total) => total
)

export const selectCurrentFrameFilters = createSelector(
  getFramesFilters,
  (filters) => filters 
)

export const selectIsFrameFiltersShown = createSelector(
  getFrameFiltersShown,
  (filters) => filters 
)