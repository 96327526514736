import { Component, OnInit, enableProdMode, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import Amplify, { Auth } from 'aws-amplify';
import { Store, select } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { selectConfiguration } from 'src/app/store/selectors/configuration.selector';
import { LoadConfiguration } from 'src/app/store/actions/configuration.actions';
import { LoadBackendConfiguration } from 'src/app/store/actions/backend-configuration.actions';
import { Load, ECollectionItemType } from 'src/app/store/actions/collection.actions';
import { selectSelectedTabNumber } from 'src/app/store/selectors/app.selectors';
import { NavigateToTab } from 'src/app/store/actions/app.actions';
import { Notification } from 'src/app/models/notification';
import { selectNotifications } from 'src/app/store/selectors/notifications.selectors';
import UploadedFile from 'src/app/models/uploaded_file';
import { FramesSelectors } from 'src/app/store/selectors/collection.selectors';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';
import { selectBackendConfiguration } from 'src/app/store/selectors/backend-configuration.selectors';
import { BackendConfiguration } from 'src/app/models/backend_configuration';
import { Configuration } from 'src/app/models/configuration';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  selectedTabNumber: number = 0;
  notifications: Notification[] = [];
  title = 'resumable-client';
  currentUser = null;
  groups: string[] = [];
  config: Configuration;
  superuser : boolean = false;
  awsSettingsLoaded : boolean = false;
  backendConfig : BackendConfiguration = null;

  config$ = this.store.pipe(select(selectConfiguration));
  backendConfig$ = this.store.pipe(select(selectBackendConfiguration));
  selectedTabNumber$ : Observable<number> = this.store.pipe(select(selectSelectedTabNumber));
  notifications$ : Observable<Notification[]> = this.store.pipe(select(selectNotifications));

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>
  ) {}

  async ngOnInit() {
    this.subscriptions.push(this.config$.subscribe(async c => {
      console.log('new config:', c);
      this.config = c;

      if (!!c.aws_cognito_region) {
        Amplify.configure(c);
        this.awsSettingsLoaded = true;
      }
    }));

    this.subscriptions.push(this.backendConfig$.subscribe( c => {
      this.backendConfig = c;
    }));

    this.subscriptions.push(
      this.selectedTabNumber$.subscribe(selectedTabNumber => {
        this.selectedTabNumber = selectedTabNumber;
      })
    );
    this.subscriptions.push(
      this.notifications$.subscribe(notifications => {
        this.notifications = notifications;
      })
    );

    this.store.dispatch(new LoadConfiguration());

    Auth.currentAuthenticatedUser()
    .then((data) => {
      this.onUserLoggedIn(data);
    })
    .catch(() => {
      let isExecuted = false;
      const checkUserInterval = setInterval(async () => {
        if (!isExecuted) {
          let user = null;
          try {
            user = await Auth.currentAuthenticatedUser();
          }
          catch {}

          if (user && !isExecuted) {
            isExecuted = true;
            clearInterval(checkUserInterval);
            await this.onUserLoggedIn(user);
          }
        }
      }, 100)
    });
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  async onUserLoggedIn(user) {
    this.currentUser = user;
    console.log("currrent user:", this.currentUser);

    const currentSession = await Auth.currentSession();
    let accessTokenPayload =  currentSession.getAccessToken().decodePayload(); 
    this.groups = accessTokenPayload['cognito:groups'];
    if (this.groups.includes('superuser')) {
      this.superuser = true;
    }

    if (this.config.isProduction) {
      enableProdMode();
    }

    this.loadData();
  }

  loadData() {

    // anything but false should keep heartbeat on
    let heartbeat: boolean = !(localStorage.getItem("heartbeat") == "false");
    if (heartbeat) {
      this.store.dispatch(new LoadBackendConfiguration());
      setInterval(() => {
        this.store.dispatch(new LoadBackendConfiguration());
      }, 1000 * 10);      
    };
    
    let configLoaded = false;
    const ussForFrames = this.config$.subscribe((config) => {
      if (!configLoaded && config.serverUrl) {
        configLoaded = true
        if (ussForFrames) {
          ussForFrames.unsubscribe();
        }
        this.store.dispatch(new Load<UploadedFile>(ECollectionItemType.Video));
        // this.store.dispatch(new Load<VideoFrame>(ECollectionItemType.VideoFrame));
        this.store.dispatch(new Load<ObjectOntologyClass>(ECollectionItemType.Ontology));
      }
    });
    const frames$ = this.store.pipe(select(FramesSelectors.selectItems));
    let framesLoaded = false;
    const ussForBaskets = frames$.subscribe((frames) => {
      if (!framesLoaded) {
        console.log('app load: frames:', frames);
        if (frames && frames.length > 0) {
          framesLoaded = true;
          if (ussForBaskets) {
            ussForBaskets.unsubscribe();
          }
          this.store.dispatch(new Load(ECollectionItemType.VideoFrame));
        }
      }
    })
    console.log('app load: uss:', ussForBaskets);
  }

  switchToTab(tabNumber: number) {
    this.store.dispatch(new NavigateToTab(tabNumber));
  }

  signOut() {
    Auth.signOut();
  }

  async handleSignInSubmit(event: any) {
    window.onbeforeunload = function() {
      return "";
    }

    console.log('sign in', JSON.stringify(event));

    alert("before login retrieval")
    const login = (document.querySelector("amplify-authenticator").shadowRoot.querySelector("amplify-sign-in").shadowRoot.querySelector("amplify-auth-fields").shadowRoot.querySelector("amplify-username-field").shadowRoot.querySelector("amplify-form-field").shadowRoot.getElementById("username") as HTMLInputElement).value;
    alert(login);
    const pass = (document.querySelector("amplify-authenticator").shadowRoot.querySelector("amplify-sign-in").shadowRoot.querySelector("amplify-auth-fields").shadowRoot.querySelector("amplify-password-field").shadowRoot.querySelector("amplify-form-field").shadowRoot.getElementById("password") as HTMLInputElement).value;
    alert(pass);

    console.log('sign in 2', login, pass);


    alert(event);
    await Auth.signIn(login, pass);

    return false;
  }

}
