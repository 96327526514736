import { initialContentsTabComponentState, ContentsTabComponentState } from '../state/contents-tab-component.state';
import { ContentsTabComponentActions, EContentsTabComponentActions } from '../actions/contents-tab-component.actions';

export function contentsTabComponentReducers( 
	state = initialContentsTabComponentState,
	action: ContentsTabComponentActions
) : ContentsTabComponentState {
	switch (action.type) {
		case EContentsTabComponentActions.SelectedFramesChanged:
			return {
				... state,
				... { selectedFrames: action.frames }
      }
    case EContentsTabComponentActions.SelectedVideosChanged:
			return {
				... state,
				... { selectedVideos: action.videos }
			}
		case EContentsTabComponentActions.NavigateToTab:
			return {
				... state,
				... { selectedTabNumber: action.tabNumber }
			}
		case EContentsTabComponentActions.FramesPageLoadSuccess:
			return {
				... state,
				pageFrameIds: action.frames.map(f => f.id),
				totalFramePages: Math.ceil(action.metadata.total / action.metadata.pageSize)
			}
		case EContentsTabComponentActions.NavigateToFramesPage:
			return {
				... state,
				framesPage : action.pageNumber
			}
		case EContentsTabComponentActions.FramesFiltersChanged:
			return {
				... state,
				framesFilters: action.filters
			}
		case EContentsTabComponentActions.ToggleFrameFilters:
			return {
				... state,
				frameFiltersShown: !state.frameFiltersShown
			}
		default:
			return state;
	}
}