import { initialNotificationsState } from '../state/app.state';
import { NotificationsActions, ENotificationsActions } from '../actions/notifications.actions';
import { Notification } from 'src/app/models/notification';
import * as _ from 'lodash';


export function notificationsReducers( 
	state = initialNotificationsState,
	action: NotificationsActions
) : Record<number, Notification> {
	switch (action.type) {
		case ENotificationsActions.ShowNotification:
      const newEntry = {};
			const key = _.uniqueId();
			newEntry[key] = {
        id: key,
        text: action.text,
        type: action.typeOfNotification,
        date: new Date()
			};
			return {
				...state,
				...newEntry
      };
    case ENotificationsActions.HideNotification:
      return _.omit(state, [action.id]);
    default:
      return state;
  }
}