import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { HttpAuthClient }       from 'src/app/services/http_auth';
import { FetchAuthClient }      from 'src/app/services/fetch_auth';
import { Auth } from 'aws-amplify';

import { Subscription, Observable } from 'rxjs/Rx';
import { of } from 'rxjs';

import  User from 'src/app/models/user'
import { UserService } from 'src/app/services/user.service';

import { Store, select } from '@ngrx/store';
import { AppState } from '../../store/state/app.state';
import { selectConfiguration } from '../../store/selectors/configuration.selector';
import * as _ from 'lodash';

@Component({
  selector: 'debug-auth',
  templateUrl: './debug-auth.component.html',
  styleUrls: ['./debug-auth.component.scss']
})

export class DebugAuthComponent implements OnInit, OnDestroy {
  protected subscriptions : Subscription[] = [];

  private config$ = this.store.pipe(select(selectConfiguration));
  serverUrl: string;
  userData: string;

  users : User[];

  constructor(
    private httpAuthClient : HttpAuthClient,
    private userService    : UserService,
    protected store : Store<AppState>
  ) { 
		this.subscriptions.push(
			this.config$
      .subscribe(config => {
        this.serverUrl = config.serverUrl
      }));
	}
  

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
  
  clientData = [];
  serverData = []; 

  async authCheck()  {
    const authResponse: Response = await FetchAuthClient.fetch(`${this.serverUrl}/api/auth_test`, {
          method: 'GET',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        console.log("authResponse", authResponse);
        if (authResponse.status == 200) {
          const authData = await authResponse.json();
          console.log("authData", authData);
          this.userData = "Auth as:" + authData.auth.username;

          this.serverData.push({ key: 'username', value: authData.auth.username});
          this.serverData.push({ key: 'ip'      , value: authData.ip});
          this.serverData.push({ key: 'groups'  , value: authData.auth['cognito:groups'].toString()});

          this.serverData.push({ key: 'auth_time'  , value: new Date(authData.auth.auth_time * 1000).toString() });
          this.serverData.push({ key: 'auth_expire', value: new Date(authData.auth.exp       * 1000).toString() });
          this.serverData.push({ key: 'auth_iat'   , value: new Date(authData.auth.iat       * 1000).toString() });
        } else {
          this.userData = "Auth failed";
        }
  }

  loggingData = []; 

  async loggingCheck()  {
    const loggingResponse: Response = await FetchAuthClient.fetch(`${this.serverUrl}/api/logging`, {
          method: 'GET',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json'
          }
        });
    console.log("logResponse", loggingResponse);
    if (loggingResponse.status == 200) {
      this.loggingData = await loggingResponse.json();
      console.log("authData", this.loggingData);        
    }
  }

  async loggingToggle(name: string, value: boolean)
  {    

    let body_text : string = JSON.stringify( {name : name, value : value } );

    console.log("body_text", body_text);        
    const loggingResponse: Response = await FetchAuthClient.fetch(`${this.serverUrl}/api/logging/update`, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json'
      },
      body: body_text
    });
    console.log("logResponse", loggingResponse);
    if (loggingResponse.status == 200) {
      await loggingResponse.json();    
      this.loggingCheck();
    }
  }


  async clientCheck()  {
       Auth.currentAuthenticatedUser()
      .then(currentUser => 
          {
            console.log(currentUser);
            this.clientData.push({ key: 'username', value: currentUser.username});
            this.clientData.push({ key: 'email', value: currentUser.attributes.email});
            this.clientData.push({ key: 'email_verified', value: currentUser.attributes.email_verified});
            this.clientData.push({ key: 'phone_number', value: currentUser.attributes.phone_number});
            this.clientData.push({ key: 'phone_number_verified', value: currentUser.attributes.phone_number_verified});

            let pool = currentUser.pool;
            this.clientData.push({ key: 'pool', value: pool.userPoolId});


          }         
      );

      Auth.currentSession()
      .then(currentSession => 
          {
            console.log(currentSession);
            let accessTokenPayload =  currentSession.getAccessToken().decodePayload(); 
            let groups = accessTokenPayload['cognito:groups'];
            this.clientData.push({ key: 'groups', value: groups.toString()});

            this.clientData.push({ key: 'auth_time'  , value: new Date(accessTokenPayload.auth_time * 1000).toString() });
            this.clientData.push({ key: 'auth_expire', value: new Date(accessTokenPayload.exp       * 1000).toString() });
            this.clientData.push({ key: 'auth_iat'   , value: new Date(accessTokenPayload.iat       * 1000).toString() });
          }         
      );

  }

  utilsAuth = { 
      uploader  : false,
      manager   : false,
      annotator : false,

      dropPrivledege : false
   };

  async onGroupCheckChanged(value: string, event: any) {
    this.utilsAuth[value] = event.target.checked;
  }

  async onPrivledegeChanged(event: any) {
    this.utilsAuth['dropPrivledege'] = event.target.checked;
  }

  async dropPrivledege() {
    localStorage.setItem("su", JSON.stringify(this.utilsAuth));
    console.log("Stored:", localStorage.getItem("su"));
  }

  doHeartbeat: boolean = false;

  async onHeartbeatChanged(event: any)
  {
    this.doHeartbeat = event.target.value;
    localStorage.setItem("heartbeat", this.doHeartbeat.toString());
  }

  ngOnInit(): void {
    this.url = this.serverUrl + "/api/";

    let stored = localStorage.getItem("su");
    console.log("Loaded:", stored);
    if (stored) {
        let parsed = JSON.parse(stored);
        this.utilsAuth = {
          uploader  : parsed.uploader,
          manager   : parsed.manager,
          annotator : parsed.annotator,
    
          dropPrivledege : parsed.dropPrivledege
        }          
    }

    let heartbeatString = localStorage.getItem("heartbeat");
    this.doHeartbeat = (heartbeatString == "true");
    this.clientCheck();

    /* Put this to the common place */
    Auth.currentAuthenticatedUser()
    .then(currentUser => 
      {
        console.log(currentUser);
        let nickname : string = currentUser.username;
        let email    : string = currentUser.attributes.email;

        Auth.currentSession()
        .then(currentSession => 
            {
              console.log(currentSession);
              let accessTokenPayload =  currentSession.getAccessToken().decodePayload(); 
              let groups : string[] = accessTokenPayload['cognito:groups'];
              this.userService.addUser(nickname, email, groups);
            });
      });

    
    

    /* End of user  register block*/

    this.userService.getUsers()
    .subscribe(data => {
      this.users = data;
      console.log(data);
    });
  }

  url    : string = "";
  method : string = "get";

  methodSelected(event : any)  {
    this.method = event.target.value;
  }

  urlChanged(event : any) {
    this.url = event.target.value;
  }


  async submitRequest() {
    switch(this.method) {
      case 'get'    : 
            this.httpAuthClient.get   <any>(this.url, {}).subscribe(data => {  console.log(data); }); 
          break;
      case 'post'   : 
            this.httpAuthClient.post  <any>(this.url, {}, {}).subscribe(data => {  console.log(data); }); ; 
          break;
      case 'put'    : 
            this.httpAuthClient.put   <any>(this.url, {}, {}).subscribe(data => {  console.log(data); }); ; 
          break;
      case 'delete' : 
            this.httpAuthClient.delete<any>(this.url, {}).subscribe(data => {  console.log(data); }); ; 
            break;

    }
  }



}
