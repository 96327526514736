import { Injectable } from '@angular/core';
import { AppState } from '../state/app.state';
import { Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { ShowNotification, ENotificationsActions, HideNotification } from '../actions/notifications.actions';
import { selectNotifications } from '../selectors/notifications.selectors';
import { of } from 'rxjs';
import { switchMap, withLatestFrom, concatMap} from 'rxjs/operators';

@Injectable()
export class NotificationsEffects {
	constructor(
		private store: Store<AppState>,
    private actions$: Actions
  ) {}

	@Effect()
	ShowNotification$ = this.actions$.pipe(
    ofType<ShowNotification>(ENotificationsActions.ShowNotification),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store.select(selectNotifications))
    )),
    switchMap(([action, notifications]) => {
      const foundNotifications = notifications.filter(notification => 
        notification.text == action.text && notification.type == action.typeOfNotification);
      if (foundNotifications.length > 0) {
        setTimeout(() => {
          for (let notification of foundNotifications) {
            this.store.dispatch(new HideNotification(notification.id));
          }
        }, 5000);
      }
      return [];
		})
	)}