import { Injectable } from '@angular/core';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';
import { UploadsService } from 'src/app/services/uploads.service';
import UploadedFile from 'src/app/models/uploaded_file';
import { Load, ECollectionActions, LoadSuccess, ECollectionItemType } from '../actions/collection.actions';

@Injectable()
export class VideosEffects {
	constructor(
		private videosService: UploadsService,
		private actions$: Actions
	) {}

	@Effect()
	getVideos$ = this.actions$.pipe(
		ofType<Load<UploadedFile>>(ECollectionActions.Load),
		switchMap(() => this.videosService.getUploads()),
		switchMap((videos: UploadedFile[]) => [
			new LoadSuccess(videos, ECollectionItemType.Video)
		])
	)
}