import { Auth } from 'aws-amplify';
import {utilsAuth} from './utils_auth';

export class FetchAuthClient {

  static async fetch(url: string, options : any ) : Promise<Response> {
    let result : Promise<Response> = Auth.currentSession().then(
      async currentSession => {
        console.log('fetch_auth: current session:', currentSession);
        console.log('fetch_auth: token:', currentSession.getAccessToken());

        let toDrop = utilsAuth.getDroppedPrivelegeArray();
        if (toDrop) {
          options.headers['x-hawk-dropto'] = JSON.stringify(toDrop);
        }    
        options.headers.accesstoken = currentSession.getAccessToken().getJwtToken();
        let result : Promise<Response> = fetch(url, options);

        return result;
      }
    );
    return result;
  }


}
