import { Action } from '@ngrx/store';
export enum EBasketComponentActions {
  ChangeFrameBasket = '[BasketComponent] ChangeFrameBasket',
  ChangeClassBasket = '[BasketComponent] ChangeClassBasket'
}

export class ChangeFrameBasket implements Action {
  public readonly type = EBasketComponentActions.ChangeFrameBasket;
  constructor (public basketId: number) {}
}

export class ChangeClassBasket implements Action {
  public readonly type = EBasketComponentActions.ChangeClassBasket;
  constructor (public basketId: number) {}
}

export type BasketComponentActions = ChangeFrameBasket | ChangeClassBasket;