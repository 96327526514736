export enum ActorTarget {
    Video = 'video',
    Frame = 'frame',
    Basket = 'basket',
    Dataset = 'dataset'
  }
  

export class Actor {
    id        : number;
    name      : string;
    use_cpu   : boolean;
    use_gpu   : boolean;
    executer  : string;
    function  : string; 
    parameters: string;
    internal  : boolean;
    target    : string;
}
