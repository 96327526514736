import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import Segment from 'src/app/models/segment';
import { FrameLabel } from 'src/app/models/frame_label';
import Point from 'src/app/models/point';
import ObjectOntologyClass from 'src/app/models/object_ontology_class';
import * as _ from 'lodash';
import { Observable, Subscription, of, combineLatest } from 'rxjs';
import { withLatestFrom, map, switchMap, concatMap, mergeMap } from 'rxjs/operators';
import { selectJob, selectCurrentFrameIndex } from 'src/app/store/selectors/annotator-component.selectors';
import { select, Store } from '@ngrx/store';
import { Job, JobStatus } from 'src/app/models/job';
import { AppState } from 'src/app/store/state/app.state';
import { VideoFrameStatus, VideoFrame, videoFrameStatusToStr } from 'src/app/models/video_frame';
import { UpdateJob } from 'src/app/store/actions/jobs.actions';
import { ShowNotification } from 'src/app/store/actions/notifications.actions';
import { NotificationType } from 'src/app/models/notification';
import { NavigateToTab } from 'src/app/store/actions/contents-tab-component.actions';
import { ChangeFrameIndex } from 'src/app/store/actions/annotator-component.actions';
import { Update, ECollectionItemType } from 'src/app/store/actions/collection.actions';
import { OntologySelectors } from 'src/app/store/selectors/collection.selectors';
import { selectLabelsForFrame } from 'src/app/store/selectors/frame-labels.selectors';
import { CreateFrameLabel, DeleteFrameLabel, LoadLabelsForFrames } from 'src/app/store/actions/frame-labels.actions';

@Component({
  selector: 'annotator',
  templateUrl: './annotator.component.html',
  styleUrls: ['./annotator.component.scss']
})
export class AnnotatorComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('canvas', { static: false }) canvas: ElementRef;

  currentFrameIndex: number = 0;
  currentFrame: VideoFrame = null;
  currentFrameLabels: FrameLabel[] = [];
  job: Job;
  allClasses: Record<number,ObjectOntologyClass> = {};

  private job$ : Observable<Job> = this.store.pipe(select(selectJob));
  private currentFrameIndex$ : Observable<number> =
    this.store.pipe(select(selectCurrentFrameIndex));
  private currentFrame$: Observable<VideoFrame> =
    this.currentFrameIndex$.pipe(
      withLatestFrom(this.job$),
      map(([frameIdx, job]) => 
        job && job.frame_basket && job.frame_basket.videoFrames
        ? job.frame_basket.videoFrames[frameIdx]
        : null
      )
    );
  private currentFrameLabels$ : Observable<FrameLabel[]> =
    this.currentFrame$.pipe(
      switchMap(frame => this.store.pipe(select(selectLabelsForFrame, {frameID: frame.id})))
    );
  private allClasses$: Observable<Record<number, ObjectOntologyClass>> = this.store.pipe(select(OntologySelectors.selectDict));
  private subscriptions: Subscription[] = [];

  ctx: any;
  segments: Segment[] = [];
  segmentTexts: Record<number, string> = {};

  selectionStart: Point = null;
  selectionEnd: Point = null;
  scale : number = 1.0;
  imageEl: HTMLImageElement = null;
  selectedClass? : ObjectOntologyClass = null;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.store.dispatch(new ChangeFrameIndex(0));
    this.subscriptions.push(
      this.job$.subscribe((job: Job) => {
        if (!this.job || this.job.id != job.id) {
          console.log('subs: changed job:', job);
          this.job = job;

          if (job && job.frame_basket && job.frame_basket.videoFrameIds && job.frame_basket.videoFrameIds.length > 0) {
            this.store.dispatch(new LoadLabelsForFrames(job.frame_basket.videoFrameIds));
          }
        }
      })
    );
    this.subscriptions.push(
      this.currentFrameIndex$.subscribe((index: number) => {
        console.log('subs: changed frame index:', index);
        this.currentFrameIndex = index;
      })
    );
    this.subscriptions.push(
      this.allClasses$.subscribe((classes : ObjectOntologyClass[]) => {
        this.allClasses = classes;
      })
    );
    this.subscriptions.push(
      this.currentFrame$.subscribe(frame => {
        console.log('subs: changed frame:', frame);
        this.currentFrame = frame;
        this.updateFrame();
      })
    );
    this.subscriptions.push(
      this.currentFrameLabels$.subscribe(labels => {
        this.currentFrameLabels = labels;
        console.log('subs: frame labels:', labels);

        setTimeout(() => {
          this.fillSegmentsFromLabels(labels);
        }, 10);
      })
    );
  }

  ngOnDestroy() {
    for (let subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.ctx = this.canvas.nativeElement.getContext('2d');
  }

  updateFrame() {
    if (this.currentFrame) {
      this.imageEl = new Image();
      this.imageEl.onload = () => {
        if (this.imageEl.width && this.imageEl.height) {
          this.canvas.nativeElement.height = this.imageEl.height;
          this.canvas.nativeElement.style['height'] = this.imageEl.height;
          this.canvas.nativeElement.width = this.imageEl.width;
          this.canvas.nativeElement.style['width'] = this.imageEl.width;
          this.ctx.drawImage(this.imageEl, 0, 0);
          this.scale = this.canvas.nativeElement.clientWidth / this.imageEl.width;

          setTimeout(() => {
            const newScale = this.canvas.nativeElement.clientWidth / this.imageEl.width;
            this.scale = newScale;
          }, 50)
        }

        this.refresh();
      };
      this.imageEl.src = this.currentFrame.url;
      console.log('set image src:', this.currentFrame.url);
    }
  }

  fillSegmentsFromLabels(labels: FrameLabel[]) {
    this.segments = [];
    for (let label of labels) {
      this.segments.push({
        id: label.id,
        startTimestamp: null,
        endTimestamp: null,
        color: label.color,
        enabled: true,
        ontologyClasses: [ this.allClasses[label.object_class_id] ],
        points: [{
          x: label.p1.x,
          y: label.p1.y,
        }, {
          x: label.p2.x,
          y: label.p1.y,
        }, {
          x: label.p2.x,
          y: label.p2.y,
        }, {
          x: label.p1.x,
          y: label.p2.y,
        }]
      })
    }
    this.refresh();
  }

  refresh() {
    console.log("refresh");

    this.ctx.drawImage(this.imageEl, 0, 0);

    for (let segment of this.segments.filter(s => s.enabled)) {
      this.drawSegment(segment);
    }
    this.drawUserRectangle();
  }

  // Draws saved segment
  private drawSegment(segment: Segment) {
    const color =
      segment.color.startsWith
      ? (
        segment.color.startsWith('#')
        ? segment.color
        : ('#' + parseInt(segment.color).toString(16)))
      // @ts-ignore
      : ('#' + segment.color.toString(16));
    // Drawing segment itself
    this.ctx.beginPath();
    this.ctx.moveTo(segment.points[0].x, segment.points[0].y);
    for(let point of segment.points.slice(1)) {
      this.ctx.lineTo(point.x, point.y);
    }
    this.ctx.closePath();

    this.ctx.strokeStyle = color;
    const lineWidth = Math.floor(2 / this.scale);
    this.ctx.lineWidth = lineWidth;
    this.ctx.stroke();

    // Drawing label rectangle
    const rectWidth = Math.floor(49 / this.scale);
    const rectHeight = Math.floor(16 / this.scale);
    const rectFontSize = Math.floor(12 / this.scale);
    this.ctx.beginPath();
    this.ctx.moveTo(segment.points[0].x - lineWidth/2, segment.points[0].y);
    this.ctx.lineTo(segment.points[0].x - lineWidth/2, segment.points[0].y - rectHeight);
    this.ctx.lineTo(segment.points[0].x + rectWidth - lineWidth/2, segment.points[0].y - rectHeight);
    this.ctx.lineTo(segment.points[0].x + rectWidth - lineWidth/2, segment.points[0].y);
    this.ctx.closePath();

    this.ctx.fillStyle = color;
    this.ctx.fill();

    // Drawing label text
    this.ctx.fillStyle = '#000000';
    this.ctx.font = `bold ${rectFontSize}px IBMPlexSans`;
    this.ctx.textBaseline = "bottom";
    this.ctx.fillText(this.getSegmentText(segment), segment.points[0].x - lineWidth/2 + 4, segment.points[0].y);

  }

  // Draws currently created
  private drawUserRectangle()
  {
    if (this.selectionStart && this.selectionEnd) {
      this.ctx.fillStyle = '#0000ff';
      this.ctx.globalAlpha = 0.2;
      this.ctx.fillRect(
        this.selectionStart.x / this.scale,
        this.selectionStart.y / this.scale,
        (this.selectionEnd.x - this.selectionStart.x) / this.scale,
        (this.selectionEnd.y - this.selectionStart.y) / this.scale
      );
      this.ctx.globalAlpha = 1.0;
    }
  }

  onCanvasMouseDown(e: MouseEvent) {
    if(this.currentFrame
      && this.currentFrame.status !== VideoFrameStatus.None
      && this.currentFrame.status !== VideoFrameStatus.InProgress
      && this.currentFrame.status !== VideoFrameStatus.Classified) {
        console.log(`You cannot add a new counturs in ${this.currentFrame.status} status`);
        this.store.dispatch(new ShowNotification(`You cannot add a new counturs in ${videoFrameStatusToStr(this.currentFrame.status)} status`, NotificationType.Information));
        return;
      }
    if (this.selectionStart) {
      console.error(`Selection is already stated at ${this.selectionStart.x},${this.selectionStart.y}. Current position: ${e.clientX}, ${e.clientY}.`);
      return;
    }

    console.log('Selection start:', e.offsetX, e.offsetY);
    this.selectionStart = new Point(e.offsetX, e.offsetY);
  }

  onCanvasMouseUp(e: MouseEvent) {
    console.log('Selection end:', e.offsetX, e.offsetY);

    if (!this.currentFrame) {
      return;
    }

    if (!this.selectedClass) {
      this.store.dispatch(new ShowNotification("Class is not selected", NotificationType.Information));
    }
    else if (this.selectionStart && this.selectionEnd) {
      const selectedClasses = this.selectedClass ? [ this.selectedClass ] : [];
      const newSegment : Segment = {
        ontologyClasses: selectedClasses,
        startTimestamp: null,
        endTimestamp : null,
        points: [{
          x: this.selectionStart.x / this.scale,
          y: this.selectionStart.y / this.scale
        },{
          x: this.selectionEnd.x / this.scale,
          y: this.selectionStart.y / this.scale
        },{
          x : this.selectionEnd.x / this.scale,
          y: this.selectionEnd.y / this.scale
        },{
          x : this.selectionStart.x / this.scale,
          y: this.selectionEnd.y / this.scale
        }],
        color: '#0000ff',
        enabled: true
      };
      this.segments.push(newSegment);
    
      /* Some reporting to server */
      /* Login would be filled later */
      let label: FrameLabel  = {
        frame : this.currentFrame.id,
        object_class_id: this.selectedClass.id,
        user : "---",
        p1 : {
          x: Math.round(this.selectionStart.x / this.scale),
          y: Math.round(this.selectionStart.y / this.scale)
        },
        p2 : {
          x: Math.round(this.selectionEnd.x / this.scale),
          y: Math.round(this.selectionEnd.y / this.scale)
        },
        color: (256*256*125 + 256 * 200 + 213).toString(),
        enabled: true
      }

      const frameLabels$ = this.store.pipe(select(selectLabelsForFrame, { frameID: this.currentFrame.id }));
      let uss = null;
      let executed = false;
      uss = frameLabels$.subscribe(labels => {
        if (uss) {
          uss.unsubscribe();
        }
        if (!executed) {
          executed = true;
          console.log('countur saved');
          if(this.currentFrame.status === VideoFrameStatus.None || this.currentFrame.status === VideoFrameStatus.Classified) {
            this.updateFrameInProgress();
          }
        }
      })
      this.store.dispatch(new CreateFrameLabel(label));
    }

    this.selectionStart = null;
    this.selectionEnd = null;

    this.refresh();
  }

  onCanvasMouseMove(e: MouseEvent) {
    if (this.selectionStart) {
      this.selectionEnd = new Point(e.offsetX, e.offsetY);
      this.refresh();
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscape(e: KeyboardEvent) {
    this.selectionStart = null;
    this.selectionEnd = null;
    this.refresh();
  }

  selectClass(oclass: ObjectOntologyClass) {
    this.selectedClass = oclass;
  }

  selectSegment(segment: Segment, e: MouseEvent) {
    //@ts-ignore
    segment.enabled = e.target.checked;
    this.refresh();
  } 

  getSegmentText(segment: Segment) {
    if (segment.id) {
      if (segment.id in this.segmentTexts) {
        return this.segmentTexts[segment.id];
      }
      else {
        let text;
        if (segment.ontologyClasses && segment.ontologyClasses.length > 0) {
          const sameNameCount = _.chain(this.segmentTexts)
          .values()
          .filter(name => name.startsWith(segment.ontologyClasses[0].name))
          .value()
          .length + 1;
          text = segment.ontologyClasses[0].name + " " + sameNameCount;
        }
        else {
          text = '<no class>';
        }
        this.segmentTexts[segment.id] = text;
        return text;
      }
    }
    else {
      return segment.ontologyClasses && segment.ontologyClasses.length > 0
      ? segment.ontologyClasses[0].name
      : '<no class>';
    }
  }

  deleteSegment(segment: Segment) {
    _.remove(this.segments, segment);
    this.store.dispatch(new DeleteFrameLabel(segment.id))
    this.refresh();
  }

  backward() {
    if (this.currentFrameIndex > 0) {
      this.store.dispatch(new ChangeFrameIndex(this.currentFrameIndex - 1));
    }
  }

  forward() {
    if (this.currentFrameIndex < this.job.frame_basket.videoFrames.length -1) {
      this.store.dispatch(new ChangeFrameIndex(this.currentFrameIndex + 1));
    }
  }

  updateFrameInProgress() {
    const updatedJob = {...this.job, status: JobStatus.InProcess};
    this.store.dispatch(new UpdateJob(updatedJob));
    const updatedFrame = {...this.currentFrame, status: VideoFrameStatus.InProgress};
    this.store.dispatch(new Update<VideoFrame>(updatedFrame, ECollectionItemType.VideoFrame));
  }

  updateFrameSubmit() {
    const updatedFrame = {...this.currentFrame, status: VideoFrameStatus.Classified};
    this.store.dispatch(new Update<VideoFrame>(updatedFrame, ECollectionItemType.VideoFrame));
  }

  completeJob() {
    const updatedJob = {...this.job, status: JobStatus.Completed};
    this.store.dispatch(new UpdateJob(updatedJob));
    this.store.dispatch(new NavigateToTab(4)); // Navigate to Jobs tab
  }
}
