import { Component, OnInit } from '@angular/core';

import ActionStatus        from 'src/app/models/action_status';
import NNConfiguration from 'src/app/models/inference';

import { utilsConvert }    from 'src/app/utils/utils'

import { environment }       from 'src/environments/environment';
import { InferenceService }    from 'src/app/services/inference.service';

import * as _ from 'lodash';

@Component({
  selector: 'debug-ml',
  templateUrl: './debug-ml.component.html',
  styleUrls: ['./debug-ml.component.scss']
})

export class DebugMLComponent implements OnInit {
  nnconfigurations       : NNConfiguration[] = [];
  
  constructor(
    private inferenceService    : InferenceService,
  ) { }



  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
     this.inferenceService.getNNConfigurations()
    .subscribe(data => {
      this.nnconfigurations = data;
      console.log(data);
    });
  }

  async refreshInferences()
  {
     this.refresh();

  }

  getSimpleSize    = utilsConvert.getSimpleSize;
  getSimpleDate    = utilsConvert.getSimpleDate;
  getSimpleTime    = utilsConvert.getSimpleTime;
  getColorCode     = utilsConvert.getColorCode;
  getPrintableHash = utilsConvert.getPrintableHash;

}
