import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Actions, ofType, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../state/app.state';
import * as _ from 'lodash';
import { FrameLabelingService } from 'src/app/services/labeling.service';
import { CreateFrameLabel, CreateFrameLabelSuccess, DeleteFrameLabel, DeleteFrameLabelSuccess, EFrameLabelsActions, LoadLabelsForFrame, LoadLabelsForFrames, LoadLabelsForFrameSuccess } from '../actions/frame-labels.actions';

@Injectable()
export class FrameLabelsEffects {
	constructor(
		private store: Store<AppState>,
		private frameLabelingService: FrameLabelingService,
    private actions$: Actions
  ) {}

	@Effect()
	getLabelsForFrame$ = this.actions$.pipe(
		ofType<LoadLabelsForFrame>(EFrameLabelsActions.LoadForFrame),
		switchMap((action) => this.frameLabelingService.getLabeling(action.frameID)),
		switchMap((labels) => {
			return [
				new LoadLabelsForFrameSuccess(labels)
			];
		})
	)

	@Effect()
	getLabelsForFrames$ = this.actions$.pipe(
		ofType<LoadLabelsForFrames>(EFrameLabelsActions.LoadForFrames),
		switchMap((action) => this.frameLabelingService.getLabelingForMultipleFrames(action.frameIDs)),
		switchMap((labels) => {
			return [
				new LoadLabelsForFrameSuccess(labels)
			];
		})
	)
	
	@Effect()
	createFrameLabel$ = this.actions$.pipe(
		ofType<CreateFrameLabel>(EFrameLabelsActions.Create),
		switchMap((action) => this.frameLabelingService.createLabel(action.label)),
		switchMap((label) => {
			if (label) {
				return [ 
					new CreateFrameLabelSuccess(label)
				];
			}
			else {
				return [];
			}
		})
	)

	@Effect()
	deleteFrameLabel$ = this.actions$.pipe(
		ofType<DeleteFrameLabel>(EFrameLabelsActions.Delete),
		switchMap(async (action) => {
      await this.frameLabelingService.deleteLabel(action.id);
      return action.id;
    }),
		switchMap(labelID => {
			return [
        new DeleteFrameLabelSuccess(labelID)
      ];
		})
	)
}