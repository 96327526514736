import { Component } from '@angular/core';

@Component({
  selector: 'basket-actions',
  templateUrl: './basket-actions.component.html',
  styleUrls: ['./basket-actions.component.scss']
})
export class BasketActionsComponent {
  constructor() { }
}
