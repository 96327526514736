import { Component, OnInit } from '@angular/core';

import { Actor }           from 'src/app/models/actor';
import ActionStatus        from 'src/app/models/action_status';

import { ActionsService }        from 'src/app/services/actions.service';

import * as _ from 'lodash';

@Component({
  selector: 'debug-actions',
  templateUrl: './debug-actions.component.html',
  styleUrls: ['./debug-actions.component.scss']
})

export class DebugActionsComponent implements OnInit {

  actors       : Actor[] = [];
  actionStatus : ActionStatus[] = [];
  actionsUsers : string[] = [];

  actionsReqLimit  : number = 50;
  actionsReqOffset : number = 0;
  selectedUser : string;

  checkedActionStatus  : ActionStatus[] = [];

  sort : any = {
    id            : 1,
    content_id    : 1,
    status        : 1,
    creation_date : 1,
    start_date    : 1,
    end_date      : 1,
    actor_id      : 1,
    user_id       : 1
  }

  showFinished  : boolean = false;
  actionUserVisId : number = 0;

  onIdSort() : void {
    this.sort.id = (this.sort.id + 1) % 3;
  }

  onContentIdSort() : void {
    this.sort.content_id = (this.sort.content_id + 1) % 3;
  }

  onActorIdSort() : void {
    this.sort.actor_id = (this.sort.actor_id + 1) % 3;
  }

  onUserIdSort() : void {
    this.sort.user_id = (this.sort.user_id + 1) % 3;
  }

  onStatusSort() : void {
    this.sort.status = (this.sort.status + 1) % 3;
  }

  onCreationDateSort() : void {
    this.sort.creation_date = (this.sort.creation_date + 1) % 3;
  }
   
  onStartDateSort() : void {
    this.sort.start_date = (this.sort.start_date + 1) % 3;
  }

  onEndDateSort() : void {
    this.sort.end_date = (this.sort.end_date + 1) % 3;
  }

  onShowFinishedChanged(event : any) : void {
    this.showFinished = event.target.checked;
  }

  onActionUserChanged(event : any) : void {
    this.selectedUser = event.target.value;
  }

  onLimitChanged(event : any) {
    this.actionsReqLimit = event.target.value;
  }
  onOffsetChanged(event : any)
  {
    this.actionsReqOffset = event.target.value;
  }

  constructor(
    private actionsService    : ActionsService,
  ) { }



  ngOnInit(): void {
    this.refreshActorsList();
    this.refreshActionStatus();

  }

  refreshActionStatus() {
    let filters : any [] = [];
    if (this.selectedUser) {
      filters.push({name: 'user'    , value: this.selectedUser});
    }

    filters.push({name: 'showFinished'    , value: this.showFinished});

    this.actionsService.getActionStatusEx1(
      filters, 
      [ 
        {name: 'id'            , order: this.sort.id            }, 
        {name: 'content_id'    , order: this.sort.content_id    },
        {name: 'status'        , order: this.sort.status        },
        {name: 'creation_date' , order: this.sort.creation_date },
        {name: 'start_date'    , order: this.sort.start_date    }, 
        {name: 'end_date'      , order: this.sort.end_date      },
        {name: 'actor_id'      , order: this.sort.actor_id      },
        {name: 'user_id'       , order: this.sort.user_id       }
      ],
      this.actionsReqLimit, this.actionsReqOffset )
    .subscribe(data => {
      this.actionStatus = data;
      console.log(data);
    });

     this.actionsService.getActionsUsers()
    .subscribe(data => {
      this.actionsUsers = data;
      console.log(data);
    });
    this.checkedActionStatus = [];
  }

  async refreshActorsList()
  {
    this.actionsService.getActors(null, true)
    .subscribe(data => {
      this.actors = data;
      console.log(data);
    });    
  }

  async filterActionStatus()
  {
      this.refreshActionStatus();
  }

  async deleteCheckedActionStatus(){
    const error = await this.actionsService.cancelActions(this.checkedActionStatus);
    if (error) {
      console.error(error);
      alert(error.message);
    }
    this.refreshActionStatus();
    this.checkedActionStatus = [];
  }

  onCheckActionStatusChanged(actionStatus: ActionStatus, event: any) {
    if (event.target.checked) {
      this.checkedActionStatus.push(actionStatus);
    }
    else {
      _.remove(this.checkedActionStatus, (a) => a === actionStatus);
    }
  }



}
