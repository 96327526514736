import { CollectionActions, ECollectionActions, ECollectionItemType } from '../actions/collection.actions'
import * as _ from 'lodash';
import { IWithId } from 'src/app/models/i_with_id';

const collectionReducers = <T extends IWithId>( 
	state = {},
	action: CollectionActions<T>
) : Record<number, T> => {
	switch (action.type) {
		case ECollectionActions.LoadSuccess:
			return {
				... state,
				... _.keyBy(action.items, 'id')
			};
		case ECollectionActions.CreateSuccess:
			if (action.item && action.item.id) {
				const newEntry = {};
				newEntry[action.item.id] = action.item;
				return {
					... state,
					... newEntry
				};
			}
			else {
				return state;
			}
		case ECollectionActions.UpdateSuccess:
			if (action.item && action.item.id) {
				const updatedEntry = {};
				updatedEntry[action.item.id] = {
					... state[action.item.id],
					... action.item
				};
				
				return {
					... state,
					... updatedEntry
				};
			}
			else {
				return state;
			}
		default:
			return state;
	}
}

export function buildCollectionReducer<T>(itemType: ECollectionItemType) {
	function f(state: Record<number, T>, action: CollectionActions<T>) {
		if (action.itemType == itemType) {
			return collectionReducers<T>(state, action)
		}
		else {
			return state;
		}
	}

	return f;
};