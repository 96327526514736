import { initialBasketComponentState, BasketComponentState } from '../state/basket-component.state'
import { BasketComponentActions, EBasketComponentActions } from '../actions/basket-component.actions'

export function basketComponentReducers( 
	state = initialBasketComponentState,
	action: BasketComponentActions
) : BasketComponentState {
	switch (action.type) {
    case EBasketComponentActions.ChangeFrameBasket:
      return {
        ... state,
        ... { frameBasketId: action.basketId }
			}
		case EBasketComponentActions.ChangeClassBasket:
			return {
				... state,
				... { classBasketId: action.basketId }
			}
		default:
			return state;
	}
}