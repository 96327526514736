import Tag from './tag';

export default class UploadedFile {
	id: number;
	fileName: string;
	originalName: string;
	contentType: string;
	size: number;
	url: string;
	uploader_id: string;
	uploader_ip?: string;
	upload_date: string;
	hash       : string; 
	thumbnail_url      : string;
	thumbnail_video_url: string;	
	comment?: string;
	tags: Tag[];
	deleted: boolean;
	uploaded: boolean;
}
