import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { VideoFrame } from 'src/app/models/video_frame';
import { Basket, BasketType } from 'src/app/models/basket';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { Create, ECollectionItemType } from 'src/app/store/actions/collection.actions';
import { ShowNotification } from 'src/app/store/actions/notifications.actions';
import { NotificationType } from 'src/app/models/notification';

@Component({
  selector: 'frames-actions',
  templateUrl: './frames-actions.component.html',
  styleUrls: ['./frames-actions.component.scss']
})
export class FramesActionsComponent implements OnInit {
  @Input() checkedFrames: VideoFrame[];
  @Output() onAnnotate = new EventEmitter();
  @ViewChild('createBasketModal', {static: false }) createBasketModal: ElementRef;

  newBasketName: string = null;

  constructor(
    private store: Store<AppState>
  ) { }

  ngOnInit() {
  }

  annotateFrame() {
    this.onAnnotate.emit();
  }

  async createBasket() {
    this.store.dispatch(new ShowNotification("Creating basket...", NotificationType.Information));
    const newBasket = <Basket>{
      name: this.newBasketName,
      type: BasketType.VideoFrames,
      videoFrameIds: this.checkedFrames.map(f => f.id)
    };
    this.store.dispatch(new Create(newBasket, ECollectionItemType.Basket));
  }

}
