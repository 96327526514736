import { createSelector } from '@ngrx/store'
import { AppState } from '../state/app.state'
import * as _ from 'lodash'

export class CollectionSelectors<T> {
  constructor(private collectionRetriever: (state: AppState) => Record<number,T>) {}

  selectItems = createSelector(
    this.collectionRetriever,
    (baskets : Record<number, T>) => _.values(baskets)
  )
  selectDict = createSelector(
    this.collectionRetriever,
    (baskets : Record<number, T>) => baskets
  )
  selectItem = createSelector(
    this.collectionRetriever,
    (baskets : Record<number, T>, props) => baskets[props.id]
  )
}

const getVideos = (state: AppState) => state.videos;
export const VideosSelectors = new CollectionSelectors(getVideos);

const getFrames = (state: AppState) => state.frames;
export const FramesSelectors = new CollectionSelectors(getFrames);

const getOntologyClasses = (state: AppState) => state.ontology;
export const OntologySelectors = new CollectionSelectors(getOntologyClasses);