//
// ./src/environments/environment.ts
//

export const environment = {
  production: false,
  serverUrl: 'https://api.tpshawk.com/backend',
  version : 'prod-0.0.182'
};

