import { Component, OnInit, Input } from '@angular/core';
import { Actor } from 'src/app/models/actor';

import { UploadsService } from 'src/app/services/uploads.service';
import UploadedFile from 'src/app/models/uploaded_file';
import Tag from 'src/app/models/tag';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/state/app.state';
import { Load, ECollectionItemType } from 'src/app/store/actions/collection.actions';
import { ShowNotification } from 'src/app/store/actions/notifications.actions';
import { NotificationType } from 'src/app/models/notification';

@Component({
  selector: 'videos-actions',
  templateUrl: './videos-actions.component.html',
  styleUrls: ['./videos-actions.component.scss']
})
export class VideosActionsComponent implements OnInit {
  @Input() checkedFiles: UploadedFile[];

  constructor(
    private uploadsService : UploadsService,
    private store          : Store<AppState>
  ) { }

  ngOnInit() {
  }

  getAllTags(files: UploadedFile[]) : Tag[] {
    return Object.values(_.groupBy([].concat(...files.map(f => f.tags).filter(t => t)), 'id')).map(group => group[0]);
  }

  async deleteCheckedFiles(){
    const error = await this.uploadsService.deleteUploads(this.checkedFiles); // TODO: call from effect
    if (error) {
      console.error(error);
      alert(error.message);
    }
    this.checkedFiles = [];
    this.store.dispatch(new ShowNotification("Video was deleted successfully", NotificationType.Success));
    this.store.dispatch(new Load<UploadedFile>(ECollectionItemType.Video)); // TODO: remove after first todo is fixed
  }
}
