import { Action } from '@ngrx/store';
import { CollectionMetadata } from 'src/app/models/collection_metadata';
import { VideoFrame } from 'src/app/models/video_frame';
import { VideoFrameFilters } from 'src/app/models/video_frame_filters';

export enum EContentsTabComponentActions {
	SelectedFramesChanged = '[ContentsTabComponent] Selected Frames Changed',
	SelectedVideosChanged = '[ContentsTabComponent] Selected Videos Changed',
	NavigateToTab = '[ContentsTabComponent] NavigateToTab',
	FramesPageLoadSuccess = '[ContentsTabComponent] Frames page load success',
	NavigateToFramesPage = '[ContentsTabComponent] Navigate to Frames page',
	FramesFiltersChanged = '[ContentsTabComponent] Frames filters changed',
	ToggleFrameFilters = '[ContentsTabComponent] Frame filters togggle'
}

export class SelectedFramesChanged implements Action {
  public readonly type = EContentsTabComponentActions.SelectedFramesChanged;
  constructor ( public frames: number[] ) {}
}

export class SelectedVideosChanged implements Action {
	public readonly type = EContentsTabComponentActions.SelectedVideosChanged;
	constructor ( public videos: number[] ) {}
}

export class NavigateToTab implements Action {
  public readonly type = EContentsTabComponentActions.NavigateToTab;
  constructor(public tabNumber: number) {}
}

export class FramesPageLoadSuccess implements Action {
	public readonly type = EContentsTabComponentActions.FramesPageLoadSuccess;
	constructor(public metadata: CollectionMetadata, public frames: VideoFrame[]) {}
}

export class NavigateToFramesPage implements Action {
	public readonly type = EContentsTabComponentActions.NavigateToFramesPage;
	constructor(public pageNumber: number) {}
}

export class FramesFiltersChanged implements Action {
	public readonly type = EContentsTabComponentActions.FramesFiltersChanged;
	constructor(public filters: VideoFrameFilters) {}
}

export class ToggleFrameFilters implements Action {
	public readonly type = EContentsTabComponentActions.ToggleFrameFilters;
	constructor() {}
}

export type ContentsTabComponentActions = SelectedFramesChanged | SelectedVideosChanged | NavigateToTab | FramesPageLoadSuccess | NavigateToFramesPage | FramesFiltersChanged | ToggleFrameFilters;