import { initialFrameLabelsState } from '../state/app.state'
import * as _ from 'lodash';
import { EFrameLabelsActions, FrameLabelsActions } from '../actions/frame-labels.actions';
import { FrameLabel } from 'src/app/models/frame_label';

export function frameLabelsReducers( 
	state = initialFrameLabelsState,
	action: FrameLabelsActions
) : Record<number, FrameLabel> {
	switch (action.type) {
		case EFrameLabelsActions.LoadForFrameSuccess:
			return {
				... state,
				... _.keyBy(action.labels, 'id')
			};
    case EFrameLabelsActions.CreateSuccess:
			if (action.label && action.label.id) {
				const newEntry = {};
				newEntry[action.label.id] = action.label;
				return {
					...state,
					...newEntry
				};
			}
			else {
				return state;
			}
		case EFrameLabelsActions.DeleteSuccess:
			return _.omit(state, [ action.id ]);
		default:
			return state;
	}
}