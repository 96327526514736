import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { HttpAuthClient } from './http_auth';
import User from '../models/user';
import { HttpServiceBase } from './http_service_base';
import { AppState } from '../store/state/app.state';

import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserService extends HttpServiceBase {
  constructor(
    private httpAuth: HttpAuthClient,
    protected store : Store<AppState>
  ) {
    super(store);
  }

  getUsers() : Observable<User[]> {
    // @ts-ignore
    return this.httpAuth.get<User[]>(`${this.serverUrl}/api/users/`);
  }

  async addUser(nickname: string, email: string, groups: string[]): Promise<HttpErrorResponse> {
    try {     
      console.log("addUser", name);
      await this.httpAuth.post(
      `${this.serverUrl}/api/users/create`,
      {
        nickname : nickname,
        email : email,
        groups : groups
      },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).toPromise();        
    }
    catch(e) {
      return e;
    }
    return null;
  }


}
